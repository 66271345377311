import { Box, Container, Dialog, IconButton, Slide, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PSIcon from '../../../../../images/ps-icon.png';
import Train from '../../../../../images/train.svg';
import InstagramIcon from '../../../../../images/instagram-icon.svg';
import Unicorn from '../../../../../images/unicorn.png';
import Nasdaq from '../../../../../images/nasdaq.svg';
import NasdaqIcon from '../../../../../images/nasdaq-icon.svg';
import Driver from '../../../../../images/driver.svg';
import DoubleArrow from '../../../../../images/double-arrow-down-white.png';
import Stations__mobile from './components/Stations__mobile';
import Stations__desktop from './components/Stations__desktop';
import { withWidth } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PSAccountManager from './PSAccountManager';

// import "./style.css";

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    // height: '300px',
    overflow: 'hidden',
    padding: 0,
  },
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  trainSignContainer: {
    top: '17%',
    left: '29%',
    width: '42%',
    height: '17%',
    position: 'absolute',
  },
  trainStationLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '100%',
    color: '#fff',
    width: '100%',
  },

  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  navButtonLeft: {
    left: 0,
  },
  navButtonRight: {
    right: 0,
  },
  stationName: {
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    cursor: 'pointer',
    // boxShadow: '0 0 0 20px #ffbf12',
    borderRadius: '100px',
    '&:hover': {
      boxShadow: '0 0 0 7px #ffbf127a', // Adjust halo color as needed
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)', // Adjust halo color as needed
    },
  },
});
const PSStations = ({ width, ...props }) => {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
    props.setIndex(index);
  };

  // set train sign height - using for fit thr station logo
  const elementRef = useRef(null);
  const [height, setHeight] = useState(0);
  // console.log(width);
  const sizes = { xs: 65, sm: 100, md: 115, lg: 120, xl: 135 };
  useEffect(() => {
    setHeight(sizes[width] * 1.2);
  }, [width]);

  const [stationSignHeight, setstationSignHeight] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        let heightTemp = elementRef.current.getBoundingClientRect().height;
        // setHeight(heightTemp);
        setstationSignHeight(heightTemp * 0.17);
        props.setheight(heightTemp);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    // Cleanup on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const description = [
    <Typography
      className={classes.textOpenSans}
      // variant={width === 'xs' ? 'subtitle2' : 'h6'}
      sx={{
        lineHeight: 1.4,
        textAlign: 'center',
        color: '#ffffff77',
        fontSize:  `${height * 0.12}px` , 

        // fontSize: { xs: '12px', sm: '14px', xl: '20px' }, //props.isPhone ? '12px' : '20px',
      }}
    >
      Create a Creator Account on PassionStocks to launch your personal brand as a startup
      <br /> by letting businesses & individuals buy{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/123966-what-are-limited-automated-thank-you-mentions"
      >
        shoutouts on your Instagram stories
      </a>
      .<br />
      To open a Creator Account on PassionStocks, you need a combined following of 40,000 across
      YouTube, TikTok, and Instagram, with at least 10,000 followers on TikTok.
    </Typography>,
    <Typography
      className={classes.textOpenSans}
      // variant={width === 'xs' ? 'subtitle2' : 'h6'}
      sx={{
        lineHeight: 1.4,
        textAlign: 'center',
        color: '#ffffff77',
        fontSize:  `${height * 0.12}px` , 

        // fontSize: { xs: '12px', sm: '14px', xl: '20px' }, //props.isPhone ? '12px' : '20px',
      }}
    >
      Businesses & individuals can purchase{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/123966-what-are-limited-automated-thank-you-mentions"
      >
        shoutouts on your Instagram stories{' '}
      </a>
      to gain visibility by being recognized as partners in your personal brand journey —helping you
      establish it as an asset and take it to the next level.
      <br />
      <a style={{ textDecoration: 'underline' }} href="">
        Shoutout delivery is fully automated
      </a>
      , using a{' '}
      <a style={{ textDecoration: 'underline' }} href="">
        fixed template with text & tagging only
      </a>
      .
      <br />
      Buyers can see their direct impact on your personal brand’s worth with each purchase.{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/124008-how-do-thank-you-mentions-strengthen-my-personal-brand-engage-followers-and-grow-my-niche"
      >
        {' '}
        Learn more
      </a>
    </Typography>,
    <Typography
      className={classes.textOpenSans}
      // variant={width === 'xs' ? 'subtitle2' : 'h6'}
      sx={{
        lineHeight: 1.4,
        textAlign: 'center',
        color: '#ffffff77',
        fontSize:  `${height * 0.12}px` , 

        // fontSize: { xs: '12px', sm: '14px', xl: '20px' }, //props.isPhone ? '12px' : '20px',
      }}
    >
      Achieving a $1 billion valuation for your personal brand company—either{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/124039-what-does-it-mean-to-achieve-a-1b-personal-brand-individually-and-collectively"
      >
        individually or collectively
      </a>{' '}
      with other creators, by{' '}
      <a style={{ textDecoration: 'underline' }} href="">
        merging personal brand assets
      </a>{' '}
      and/or{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/123966-what-are-limited-automated-thank-you-mentions"
      >
        selling shoutouts on your Instagram stories
      </a>{' '}
      within the PassionStocks ecosystem—is no small feat. <br />
      Reaching this milestone qualifies you for an invitation to an{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/124040-what-does-an-ipo-assessment-meeting-mean"
      >
        IPO assessment meeting
      </a>
      .{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/124038-why-cant-brand-contracts-build-a-1b-personal-brand-while-thank-you-mentions-can"
      >
        Learn more
      </a>
    </Typography>,
    <Typography
      className={classes.textOpenSans}
      // variant={width === 'xs' ? 'subtitle2' : 'h6'}
      sx={{
        lineHeight: 1.4,
        textAlign: 'center',
        color: '#ffffff77',
        fontSize:  `${height * 0.12}px` , 

        // fontSize: { xs: '12px', sm: '14px', xl: '20px' }, //props.isPhone ? '12px' : '20px',
      }}
    >
      Your personal brand company has passed the IPO assessment and <br />
      *successfully navigated the intense IPO process—a journey of several months working with
      lawyers, bankers, regulators and others. and is now listed on NASDAQ, <br />
      the prestigious stock exchange, putting you in the global spotlight alongside major companies
      like Google, Meta, Amazon, and Walmart.{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://passionstocks.helpsite.com/articles/123966-what-are-limited-automated-thank-you-mentions"
      >
        Learn more
      </a>
      <br />
      <p style={{ marginTop: '5px ', fontSize: '10px', lineHeight: '8px important!' }}>
        *Listing on Nasdaq isn’t guaranteed and may not suit every company. We include Nasdaq as a
        tangible milestone to illustrate a structured journey,
        <br /> similar to how startups grow. It’s meant to provide an idea of what your personal
        brand’s evolution could look like if treated like a startup.
      </p>
    </Typography>,
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      style={{
        position: 'relative',
        height: 'fit-content',
        padding: '0',
        marginBottom: ['2.5vh', '12.5vh', '12.5vh', '15.5vh'][index],
      }}
    >
      <div
        style={{
          // height: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* {' '}
        <Box
          position="absolute"
          top={0}
          left={0}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          padding={height * 0.018}
        >
          <img
            src={PSIcon}
            alt="PassionStocks"
            style={{ width: height * 0.2, height: height * 0.2, marginRight: height * 0.05 }}
          />
          <Typography
            className={classes.textMonarcha}
            sx={{ color: '#707070', fontSize: height * 0.1 }}
          >
            Welcome to passionstocks
          </Typography>
        </Box> */}
        <div
          style={{
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%,-50%)',
            width: 'fit-content',
          }}
        >
          <Container sx={{ position: 'relative' }}>
            <div
              style={{
                position: 'relative',
                margin: '0px auto',
                width: 'fit-content',
              }}
            >
              <Container sx={{ width: { xs: height * 1.75, sm: height * 0.95 }, margin: 0 }}>
                <div ref={elementRef} style={{ position: 'relative', height: '100%' }}>
                  {' '}
                  <img src={Train} alt="Train" style={{ width: '100%' }} />
                  <img
                    src={PSIcon}
                    alt="PassionStocks"
                    style={{ position: 'absolute', top: '43.85%', left: '42.3%', width: '16%' }}
                  />
                  <SwipeableViews
                    className={classes.trainSignContainer}
                    index={index}
                    onChangeIndex={handleChangeIndex}
                  >
                    <div
                      className={`${classes.trainStationLogo}`}
                      style={{ position: 'relative', height: stationSignHeight, width: '100%' }}
                    >
                      <img
                        src={InstagramIcon}
                        alt="Instagram"
                        style={{ width: '28%', margin: '0 auto' }}
                      />
                    </div>
                    <div
                      className={`${classes.trainStationLogo}`}
                      style={{ position: 'relative', height: stationSignHeight }}
                    >
                      <img
                        src={PSIcon}
                        alt="PassionStocks"
                        style={{ width: '28%', margin: '0 auto' }}
                      />
                    </div>
                    <div
                      className={`${classes.trainStationLogo}`}
                      style={{ position: 'relative', height: stationSignHeight }}
                    >
                      <img src={Unicorn} alt="Unicorn" style={{ width: '28%', margin: '0 auto' }} />
                    </div>
                    <div
                      className={`${classes.trainStationLogo}`}
                      style={{ position: 'relative', height: stationSignHeight }}
                    >
                      <img
                        src={NasdaqIcon}
                        alt="Nasdaq"
                        style={{ width: '28%', margin: '0 auto' }}
                      />
                    </div>
                  </SwipeableViews>{' '}
                </div>
              </Container>
            </div>
            {/* <Typography
              className={classes.textOpenSans}
              sx={{
                fontSize: { xs: '12px', sm: '14px', xl: '20px' }, //props.isPhone ? '12px' : '20px',
                lineHeight: 1.3,
                fontWeight: 'lighter',
                color: '#d3d3d3',
                textAlign: 'center',
                marginTop: height * 0.012,
                width: 'max-content',
                margin: '0 auto !important',
              }}
            >
              PassionStocks lets creators launch their personal brand{props.isPhone && <br />} as a
              startup company. <br />
            </Typography> */}
            {width !== 'xs' && (
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#909090',
                  textAlign: 'center',
                  // fontSize: { xs: height * 0.11, xl: '' },
                  lineHeight: 1.6,
                }}
              >
                Click a stop to view details.
              </Typography>
            )}
          </Container>
          {props.isPhone ? (
            <Stations__mobile
              classes={classes}
              description={description}
              index={index}
              setIndex={setIndex}
              height={height}
              handleClickOpenDialog={handleClickOpenDialog}
            />
          ) : (
            <Stations__desktop
              description={description}
              index={index}
              setIndex={setIndex}
              height={height}
              handleClickOpenDialog={handleClickOpenDialog}
            />
          )}
        </div>
      </div>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        sx={{
          background: '#121212',
          top: { xs: '10vh', xl: '30vh' },
          '& .MuiPaper-root': { overflowY: 'visible' },
        }}
      >
        <PSAccountManager isPhone={props.isPhone} handleCloseDialog={handleCloseDialog} />
      </Dialog>
    </Box>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withWidth()(PSStations);
