import PSIcon from '../../../../../../images/ps-icon.png';
import InstagramIcon from '../../../../../../images/instagram-icon.svg';
import Unicorn from '../../../../../../images/unicorn.png';
import Nasdaq from '../../../../../../images/nasdaq.svg';
import LocationStation from '../../../../../../images/location.svg';
import DoubleArrow from '../../../../../../images/double-arrow-down-white.png';

import { Box, Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stationName: {
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  thumb: {
    cursor: 'pointer',
    borderRadius: '100px',
    '&:hover': {
      boxShadow: '0 0 0 6px #ffffff7a',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 191, 18, 0.5)',
    },
  },
});

export default function Stations__desktop(props) {
  const height = props.height;
  const classes = useStyles();

  const youHerePosition = [height * 2.88, height * 1.91, height * 0.85, -height * 0.15];

  return (
    height && (
      <div>
        <Container
          sx={{
            position: 'relative',
            height: { xs: height * 1.35 },
            width: { xs: height * 4.5 },
            marginBottom: height * 0.068,
          }}
        >
          <div
            className={classes.stationName}
            style={{
              opacity: props.index === 0 ? 1 : 0.5,
              transform: 'rotate(30deg)',
              bottom: height * 0.46,
              right: height * 3.1,
            }}
          >
            <img
              src={InstagramIcon}
              alt="Instagram"
              style={{ width: height * 0.15, marginRight: height * 0.04 }}
            />
            <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.12 }}>
              Creator agency{' '}
            </Typography>
          </div>
          <div
            className={classes.stationName}
            style={{
              opacity: props.index === 1 ? 1 : 0.5,
              transform: 'rotate(30deg)',
              bottom: height * 0.58,
              right: height * 2.08,
            }}
          >
            <img
              src={PSIcon}
              alt="PassionStocks"
              style={{ width: '29px', marginRight: height * 0.04 }}
            />
            <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.12 }}>
              Passionstocks account
            </Typography>
          </div>
          <div
            className={classes.stationName}
            style={{
              opacity: props.index === 2 ? 1 : 0.5,
              transform: 'rotate(30deg)',
              bottom: height * 0.5,
              right: height * 1.035,
            }}
          >
            <img
              src={Unicorn}
              alt="Unicorn"
              style={{ width: '40px', marginRight: height * 0.04 }}
            />
            <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.12 }}>
              $1B creator agency{' '}
            </Typography>
          </div>
          <div
            className={classes.stationName}
            style={{
              opacity: props.index === 3 ? 1 : 0.5,
              transform: 'rotate(0deg) !important',
              bottom: height * 0.17,
              right: height * 0.11,
            }}
          >
            <img src={Nasdaq} alt="Nasdaq" style={{ width: height * 0.94 }} />
          </div>

          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              right: '0',
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <div
              style={{
                border: '1px solid #d2d2d2',
                height: height * 0.095,
                width: height * 3,
                borderRadius: '100px',
                margin: `0 ${height * 0.11 + 'px'}`,
              }}
            />
            <img
              src={DoubleArrow}
              alt="Direction arrow"
              style={{
                position: 'absolute',
                top: height * 0.0115,
                left: (props.index % 4) * height + height * 0.58,
                transform: 'rotate(-90deg)',
                width: props.index !== 3 ? height * 0.065 : 0,
              }}
            />
            <svg
              className={classes.thumb}
              height="192%"
              width="5.6%"
              style={{
                boxShadow: props.index === 0 ? '0 0 0 6px #ffbf127a' : '',
                position: 'absolute',
                top: -height * 0.048,
                left: '0%',
              }}
              onClick={() => props.setIndex(0)}
            >
              <circle
                cx="50%"
                cy="50%"
                r="30%"
                stroke={props.index === 0 ? '#ffbf12' : '#4d4949'}
                strokeWidth="25"
                fill={props.index === 1 ? '#ffbf12' : '#4d4949'}
              />
            </svg>
            <svg
              className={classes.thumb}
              height="192%"
              width="5.6%"
              style={{
                boxShadow: props.index === 1 ? '0 0 0 6px #ffbf127a' : '',
                position: 'absolute',
                top: -height * 0.048,
                left: '30.2%',
              }}
              onClick={() => props.setIndex(1)}
            >
              <circle
                cx="50%"
                cy="50%"
                r="30%"
                stroke={props.index === 1 ? '#ffbf12' : '#4d4949'}
                strokeWidth="25"
                fill={props.index === 1 ? '#ffbf12' : '#4d4949'}
              />
            </svg>
            <svg
              className={classes.thumb}
              height="192%"
              width="5.6%"
              style={{
                boxShadow: props.index === 2 ? '0 0 0 6px #ffbf127a' : '',
                position: 'absolute',
                top: -height * 0.048,
                left: '63.2%',
              }}
              onClick={() => props.setIndex(2)}
            >
              <circle
                cx="50%"
                cy="50%"
                r="30%"
                stroke={props.index === 2 ? '#ffbf12' : '#4d4949'}
                strokeWidth="25"
                fill={props.index === 1 ? '#ffbf12' : '#4d4949'}
              />
            </svg>
            <svg
              className={classes.thumb}
              height="192%"
              width="5.6%"
              style={{
                boxShadow: props.index === 3 ? '0 0 0 6px #ffbf127a' : '',
                position: 'absolute',
                top: -height * 0.048,
                right: '0%',
              }}
              onClick={() => props.setIndex(3)}
            >
              <circle
                cx="50%"
                cy="50%"
                r="30%"
                stroke={props.index === 3 ? '#ffbf12' : '#4d4949'}
                strokeWidth="25"
                fill={props.index === 1 ? '#ffbf12' : '#4d4949'}
              />
            </svg>
          </Box>
          <div
            style={{
              position: 'absolute',
              bottom: -height * 0.45,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              right: youHerePosition[props.index],
              transition: 'all 0.3s linear',
            }}
          >
            <img src={LocationStation} alt="You here" style={{ width: height * 0.19 }} />
            <Typography className={classes.textMonarcha} style={{ fontSize: height * 0.0715 }}>
              Your’e here
            </Typography>
          </div>
        </Container>
        <Container
          sx={{
            width: { sm: '70%', md: '75%', lg: '70%', xl: '78%' },
            height: { xs: '90px', xl: '150px' },
          }}
        >
          {props.description[props.index]}{' '}
          {props.index !== 0 && (
            <Button
              className={classes.textOpenSans}
              sx={{
                //   position: 'absolute',
                //   left: '50%',
                //   bottom: 0,
                //   transform: 'translate(-50%,0)',
                textTransform: 'none',
                color: '#fff',
                fontSize: '14px',
                lineHeight: 1.3,
                padding: '3px 8px',
                borderColor: 'white',
                borderRadius: '50px',
                margin: '12px auto 0',
                display: 'block',
                '&:hover': { borderColor: '#ffbf12' },
              }}
              variant="outlined"
              onClick={props.handleClickOpenDialog}
            >
              Busy Creator? Get an Account Manager{' '}
            </Button>
          )}
        </Container>
      </div>
    )
  );
}
