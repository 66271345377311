
export default function isDisplayedOpeningView(state = null, action) {
    switch (action.type) {
        case 'ui/isDisplayedOpeningView/add': {
            return action.payload
        }
        case 'ui/isDisplayedOpeningView/delete': {
            return false
        }

        default:
            return state
    }
}