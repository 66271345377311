import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { withWidth } from '@material-ui/core';
import JoinTheWaitlist from '../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
const Top4CreatorsGraph = ({ width }) => {
  const classes = useStyles();

  return (
    <Box sx={{ marginTop: 10 }}>
      <Typography
        className={classes.textMonarcha}
        variant="h6"
        sx={{
          display: 'block',
          textAlign: 'center',
          margin: '0 auto',
          marginBottom: 3,
          color: '#ffbf12',
          lineHeight: 1.2,
        }}
      >
        Below, you can see{width === 'xs' && <br />} the top 4 creators with
        <br />
        the highest Shoutout Sales Volume{width === 'xs' && <br />} in the last 15 minutes.
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="caption"
        sx={{
          display: 'block',
          textAlign: 'center',
          margin: '0 auto',
          marginBottom: 2,
          color: '#909090',
          fontWeight: 'lighter',
          lineHeight: 1.2,
        }}
      >
        The chart displays their total sales volume on the X-axis and the current price of their
        <br />
        Shoutout on the Y-axis, giving you a real-time snapshot of the market’s top performers.
      </Typography>
      <img
        src={'/images/top-4-creators-graph.png'}
        alt="Top 4 creators graph - illustration"
        style={{
          display: 'block',
          margin: '0 auto 50px',
          width: width === 'xs' ? '280px' : '520px',
          maxWidth: '850px',
        }}
      />
      <JoinTheWaitlist/>
    </Box>
  );
};

export default withWidth()(Top4CreatorsGraph);
