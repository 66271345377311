import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProcessSteps from '../../../../components/ProcessSteps';
import OneProduct__StoryShoutoutOI from './OneProduct__StoryShoutoutOI';

import OneProduct__AutomatedFormat from './OneProduct__TextAndTagging';
import OneProduct__PricingMethod from './OneProduct__PricingMethod';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

export default function OneProductMarketplaceSteps__mobile(props) {
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      // variant="subtitle2"
      sx={{
        position: 'absolute',
        color: index === 0 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '35%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '30px', sm: '0' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(0);
      }}
    >
      Story Shoutout <br />
      On instagram
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        position: 'absolute',
        color: index === 1 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '40%', sm: '0' },
        right: { xs: '-10%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '35px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(1);
      }}
    >
      Text & tagging <br />
      template <br />
      with auto-delivery
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      sx={{
        position: 'absolute',
        color: index === 2 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '10px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(2);
      }}
    >
      Pricing Method
    </Typography>,
  ];

  const [swipIndex, setSwipIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setSwipIndex(i);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: 'max-content',
        width: '100vw',
        padding: 0,
        margin: 0,
        marginBottom: 5,
      }}
    >
      <div style={{ zIndex: 5, marginTop: '35px', width: 'fit-content' }}>
        <ProcessSteps
          index={index}
          setIndex={setIndex}
          length={stepsNames.length}
          stepsNames={stepsNames}
        />
      </div>

      <Box sx={{ width: '56vw' }}>
        {index === 0 && (
          <OneProduct__StoryShoutoutOI
            fontStyle={classes.textMonarcha}
            setSwipIndex={handleChangeIndex}
          />
        )}
        {index === 1 && <OneProduct__AutomatedFormat fontStyle={classes.textMonarcha} />}
        {index === 2 && <OneProduct__PricingMethod fontStyle={classes.textMonarcha} />}
      </Box>
    </Box>
  );
}
