import './style.css';

import AgencyCompany from '../../../../../../../images/agency-company.png';
import GetACut from '../../../../../../../images/get-a-cut.svg';
import ManageCreators from '../../../../../../../images/manage-creators.png';
import Rectangle from '../../../../../../../images/rectangle.svg';
import ArrowAnimate from '../../../../../../../images/arrow-animate.svg';
import ArrowAnimateWhite from '../../../../../../../images/arrow-animate-white.svg';

import ArrowAnimate2 from '../../../../../../../images/arrow-animate2.svg';
import ArrowAnimateWhite2 from '../../../../../../../images/arrow-animate-white2.svg';
import People from '../../../../../../../images/people.svg';

import unicorn from '../../../../../../../images/unicorn-line-icon.svg';
import unicornYellow from '../../../../../../../images/unicorn-line-yellow-icon.svg';

import { withWidth } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

function AgencyCompanyAnimate({ width, ...props }) {
  const index = props.index;

  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 40, sm: 40, md: 40, lg: 45, xl: 48 };
  useEffect(() => {
    setScale(sizes[width]);
  }, [width]);

  const [scaleStep3, setScaleStep3] = useState(0.5);

  const [intervalId, setIntervalId] = useState(null);
  const [unicornRatio, setunicornRatio] = useState({ ratio: 33, worth: '323M' });
  useEffect(() => {
    // personal brand asset scale index + scaleArray:
    let i = 1,
      scaleArray = [0.5, 0.7, 0.9];
    // unicorn graph scale unicornRatio + ratioArray:
    const ratioArray = [
      { ratio: 100, worth: '$1B' },
      { ratio: 33, worth: '$323M' },
      { ratio: 66, worth: '$654M' },
    ];

    if (index === 2 && intervalId === null) {
      const id = setInterval(() => {
        // personal brand asset:
        setScaleStep3(scaleArray[i % 3]);
        i++;
        // unicorn graph:
        setunicornRatio(ratioArray[i % 3]);
      }, 1000);
      setIntervalId(id);
    } else if (index !== 2 && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [index, intervalId]);

  let index4WidthAsset = 0.68;
  const socialMediaContainerCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.22,
      left: '155%',
      transform: 'translate(-50%,0)',
      width: scale * 4.9,
    },
    {
      position: 'absolute',
      bottom: scale * -1.5,
      left: scale * -6.55,
      width: scale * 4.8,
    },
    {
      position: 'absolute',
      bottom: scale * -1.5 * scaleStep3,
      left: '50%',
      transform: 'translate(-50%,0)',
      width: scale * 4.8 * scaleStep3,
    },
    {
      position: 'absolute',
      bottom: scale * -2.15 * index4WidthAsset,
      left: scale * -5.5 * index4WidthAsset,
      transform: 'translate(-50%,0)',
      width: scale * 4.8 * index4WidthAsset,
    },
  ];

  const textBenithCordinate = [
    {
      position: 'absolute',
      bottom: scale * -0.47,
      left: scale * -0.01,
      fontSize: scale * 0.23,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -0.47,
      left: scale * -0.01,
      fontSize: scale * 0.23,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6 * scaleStep3,
      left: scale * -0.37 * scaleStep3,
      fontSize: scale * 0.24 * scaleStep3,
      width: 'max-content',
      opacity: 1,
    },
    {
      position: 'absolute',
      bottom: scale * -0.8 * index4WidthAsset,
      left: '50%',
      transform: 'translate(-50%,0)',
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 1,
    },
  ];

  const CollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 0,
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.44,
      left: scale * 5,
      textAlign: 'center',
      width: scale * 6.5,
      opacity: 1,
    },
  ];

  const textCollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -2.03,
      left: scale * 8.45,
      fontSize: scale * 0.24,
      textAlign: 'center',
      width: 'max-content',
      opacity: 1,
    },
  ];

  const UnicornCollectivelyCordinate = [
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.6,
      left: scale * -0.37,
      fontSize: scale * 0.24,
      width: 'max-content',
      opacity: 0,
    },
    {
      position: 'absolute',
      bottom: scale * -1.56,
      left: '50%',
      transform: 'translate(-50%,0)',
      textAlign: 'center',
      width: 'max-content',
      opacity: 1,
    },
  ];

  function createArrows1() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 5; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.3,
            position: 'absolute',
            transform: 'rotate(90deg)',
            right: 0,
            bottom: i * gap * 2,
          }}
        />,
      );
    }
    for (let i = 1; i <= 4; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.29,
            position: 'absolute',
            right: i * gap * 2,
            bottom: 4.12 * gap * 2,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate}
        alt="path"
        style={{
          transform: 'rotate(90%)',
          width: scale * 0.8,
          position: 'absolute',
          right: 4 * gap * 2.45,
          bottom: 5 * gap * 1.42,
        }}
      />,
    );
    elements.push(
      <Typography
        className={props.fontStyle}
        key={'$'}
        style={{
          fontSize: scale * 1.4,
          position: 'absolute',
          right: -scale * 0.2,
          bottom: -scale * 1.95,
          color: '#909090',
        }}
      >
        $
      </Typography>,
    );

    return elements;
  }

  function createArrows2() {
    let elements = [];
    let gap = scale * 0.3;
    for (let i = 0; i < 5; i++) {
      elements.push(
        <img
          key={i}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.3,
            position: 'absolute',
            transform: 'rotate(90deg)',
            left: 0,
            bottom: i * gap * 2,
          }}
        />,
      );
    }
    for (let i = 1; i <= 4; i++) {
      elements.push(
        <img
          key={i + 'l'}
          src={Rectangle}
          alt="path"
          style={{
            width: scale * 0.29,
            position: 'absolute',
            left: i * gap * 2,
            bottom: 4.12 * gap * 2,
          }}
        />,
      );
    }
    elements.push(
      <img
        key={'a'}
        src={ArrowAnimate2}
        alt="path"
        style={{
          transform: 'rotate(180%)',
          width: scale * 0.8,
          position: 'absolute',
          left: 4 * gap * 2.45,
          bottom: 5 * gap * 1.42,
        }}
      />,
    );
    elements.push(
      <img
        src={People}
        key={'p'}
        style={{
          width: scale * 4.2,
          position: 'absolute',
          left: -scale * 1.92,
          bottom: -scale * 1.95,
        }}
      />,
    );

    return elements;
  }

  useEffect(() => {
    if (index === 2) {
      const container = document.querySelector('.arrowContainer1');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  useEffect(() => {
    if (index === 2) {
      const container = document.querySelector('.arrowContainer2');
      const elements = Array.from(container.children);
      let index = 0;
      const intervalTime = 90;

      setInterval(() => {
        // Remove highlight class from all elements

        // Skip the last two elements
        if (index < elements.length - 2) {
          // Apply highlight class to the current element
          elements[elements.length - 2].src = ArrowAnimate2;

          elements[index].classList.add('highlight');
          index++;
        } else {
          if (index < elements.length - 1) {
            elements[index].src = ArrowAnimateWhite2;
            index++;
          } else {
            elements.forEach((el) => el.classList.remove('highlight'));
            // Reset index to 0 to repeat the cycle
            index = 0;
          }
        }
      }, intervalTime);
    }
  }, [index]);

  return (
    <Box
      sx={{
        margin: `${scale * 1.25}px auto 0`,
        padding: '20px !important',
        position: 'relative',
        width: scale * 4.84,
        height: scale * 5.3,
      }}
    >
      <div style={{ width: scale * 4, height: scale * 5 }}>
        <div style={{ transition: 'all 0.2s ease', ...socialMediaContainerCordinate[index] }}>
          <img src={AgencyCompany} style={{ transition: 'all 0.2s ease', width: '100%' }} />
          {index !== 2 && (
            <Typography
              className={props.fontStyle}
              style={{
                position: 'absolute',
                color: index !== 3 ? '#ffffff66' : '#ffbf12',
                ...textBenithCordinate[index],
              }}
            >
              {index !== 3 ? 'Creators’ agency startup company' : 'Individually'}
            </Typography>
          )}
        </div>
        {index === 1 && (
          <img
            src={GetACut}
            alt="Deal Icon"
            style={{
              position: 'absolute',
              top: scale * 2.5,
              left: '50%',
              transform: 'translate(-50%,0)',
              width: scale * 1.2,
            }}
          />
        )}
        {index === 1 && (
          <img
            src={ManageCreators}
            alt="Creators"
            style={{
              position: 'absolute',
              top: scale * 1.5,
              right: scale * -7.55,
              width: scale * 6,
            }}
          />
        )}
        {index === 2 && (
          <div
            className="arrowContainer1"
            style={{
              position: 'absolute',
              right: -scale * 0.4 * 10,
              bottom: '-35%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
            }}
          >
            {createArrows1()}
          </div>
        )}
        {index === 2 && (
          <div
            className="arrowContainer2"
            style={{
              position: 'absolute',
              left: -scale * 0.4 * 10,
              bottom: '-35%',
              width: scale * 0.5 * 15,
              height: scale * 0.5 * 15,
            }}
          >
            {createArrows2()}
          </div>
        )}
        {index === 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'end',
              width: scale * 26,
              position: 'absolute',
              bottom: -scale * 5.25,
              left: scale * 2.5,
              transform: 'translate(-50%,0)',
            }}
          >
            <div
              className={props.fontStyle}
              style={{
                textAlign: 'right',
                width: scale * 2.5,
                color: '#ffbf12',
                fontSize: '18px',
                lineHeight: '18px',
                padding: '0 20px 0 0',
              }}
            >
              {unicornRatio.worth}{' '}
            </div>
            <div style={{ position: 'relative' }}>
              <p
                className={props.fontStyle}
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  position: 'absolute',
                  left: '50%',
                  top: '-24px',
                  transform: 'translate(-50%,0)',
                  margin: 0,
                  color: '#ffbf12',
                }}
              >
                {' '}
                {Math.ceil(unicornRatio.ratio)}%
              </p>{' '}
              <div
                className="MyBrandStats__IpoProgressSlider"
                style={{
                  diaplay: 'flex',
                  alignItems: 'center',
                  border: '1px solidx #fff',
                  borderRadius: scale * 4,
                  height: '17px',
                  width: scale * 13,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    background: '#ffbf12',
                    padding: '0 1px',
                    margin: 0,
                    borderRadius: '50px',
                    height: unicornRatio.ratio > 1.15 ? '15px' : '11px',
                    width: `${unicornRatio.ratio}%`,
                    transition: 'width 0.5s ease-out 0s',
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'inline-flex', alignItems: 'center', width: '170px' }}>
              <div
                style={{
                  display: 'inline-flex',
                  // flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginLeft: '10px',
                }}
              >
                <img src={unicorn} alt="Unicorn" style={{ width: '50px', opacity: 0.6 }} />
                <p
                  className={props.fontStyle}
                  style={{
                    color: '#ffffff66',
                    fontSize: '14px',
                    lineHeight: '16px',
                    margin: '0',
                    width: scale * 3,
                    marginLeft: '10px',
                  }}
                >
                  $1B creators’
                  <br />
                  agency
                </p>
              </div>
            </div>
          </div>
        )}

        <img
          src={'/images/agencies-collectively.png'}
          alt="Collectively"
          style={{ transition: 'all 0.2s ease', ...CollectivelyCordinate[index] }}
        />
        <Typography
          className={props.fontStyle}
          style={{ position: 'absolute', color: '#ffbf12', ...textCollectivelyCordinate[index] }}
        >
          Collectively
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'all 0.2s ease',
            ...UnicornCollectivelyCordinate[index],
          }}
        >
          <img
            src={unicornYellow}
            alt="Collectively"
            style={{ width: scale * 1.8, marginBottom: scale * 0.2 }}
          />
          <Typography
            className={props.fontStyle}
            style={{
              color: '#ffbf12',
              fontSize: scale * 0.4,
              lineHeight:1.1
            }}
          >
            $1B creator
            <br />
            agency
          </Typography>
          <img
            src={ArrowAnimate2}
            style={{
              position: 'absolute',
              top: '46%',
              left: scale * -1.6,
              transform: 'translate(0,-50%)',
              width: scale * 0.72,
            }}
          />
        </div>
      </div>
    </Box>
  );
}
export default withWidth()(AgencyCompanyAnimate);
