import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

export default function OneProduct__StoryShoutoutOI(props) {
  const classes = useStyles();

  

  return (
    <FadeIn>
      <Box>
        <div>
          <Typography
            className={props.fontStyle}
            variant="h5"
            sx={{
              position: 'relative',
              display: 'block',
              textAlign: 'center',
              color: '#ffffff',
              lineHeight: 1.3,
              marginTop: 2,
            }}
          >
            On PassionStocks, <span style={{ color: '#ffbf12' }}>creators</span> can{' '}
            <span style={{ color: '#ffbf12' }}>
              sell only one product:
              <br />
              <br />
              shoutout{' '}
            </span>
            on instagram story.{' '}
          </Typography>
          <br />
          <br />
</div>
      </Box>
    </FadeIn>
  );
}
