import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../../store/store';
// import "../desktop/home/style.css";
import './style.css';
import '../../../styles/app-style.css';
import '../../../styles/XD_home.css';
// import store from "../../store/store.js";

import PSLogoYellow from '../../../images/ps-icon.png';

import { Typography, Box, Collapse } from '@mui/material';
import EstimateSlide from './Estimate';
// import { scrollRestOfSlide } from './utiles';
import isMobileDevice from '../../../utiles/isMobileDevice';
import WelcomeSlide from './Welcome';
import PersonalBrandProcess from './PersonalBrandProcess';
import Copy1 from './Copy1';
import OneProductMarketplaceSteps from './OneProductMarketplaceSteps';
import Copy2 from './Copy2';

import Copy3 from './Copy3';
import ScalabilityDrivesSteps from './ScalabilityDrivesSteps';
import Copy5 from './Copy5';
import CommunityImpactSteps from './CommunityImpactSteps';
import OurMarketplace from './OurMarketplace';
import Copy4 from './Copy4';
import TreeChart from './TreeChart';
import Copy6 from './Copy6';
import RewardYourCommunity from './RewardYourCommunity';
import Copy7 from './Copy7';
import Creators__OpeningSlide from './OpeningSlide';
import { makeStyles } from '@mui/styles';
import Copy1A from './Copy1A';
import Copy1B from './Copy1B';
import Copy1C from './Copy1C';
import Footer from '../common-slides/Footer';
import PageWithScrollArrows from '../../../components/buttons/PageWithScrollArrows';
import JoinTheWaitlistSlide from '../common-slides/JoinTheWaitlist';
import Copy8 from './Copy8';
import { Button } from '@material-ui/core';
import FindOutMore from '../../../components/buttons/FindOutMore';
import Copy2A from './Copy2A';
import Top4CreatorsGraph from './Top4CreatorsGraph';
import Copy5A from './Copy5A';
import Copy5B from './Copy5B';
import Copy6A from './Copy6A';

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectNavigateBackLocation = (state) => state.navigateBackLocation;

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

const CreatorsPage = (props) => {
  const classes = useStyles();

  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigateBackLocation = useSelector(selectNavigateBackLocation);

  useEffect(() => {
    if (!windowInnerSize) {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: null,
      });
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.outerHeight, width: window.outerWidth },
      });
    }
  }, [windowInnerSize]);

  // navigate to the last position:

  useEffect(() => {
    setTimeout(() => {
      if (navigateBackLocation?.typePage === 'creator') {
        const scrollPositionUp = document.getElementById('LandingPage1');
        if (navigateBackLocation?.from === 'wishlist') {
          scrollPositionUp.scrollTo({
            top: scrollPositionUp.scrollHeight - windowInnerSize.height * 2, // windowInnerSize.height * 19,
          });
        }
        if (navigateBackLocation?.from === 'footer') {
          scrollPositionUp.scrollTo({
            top: scrollPositionUp.scrollHeight,
          });
        }
        store.dispatch({
          type: 'ui/navigateBackLocation/add',
          payload: null,
        });
      }
    }, 500);
  }, []);

  //
  useEffect(() => {
    setTimeout(() => {
      store.dispatch({
        type: 'ui/windowInnerSize/add',
        payload: { height: window.innerHeight, width: window.innerWidth },
      });
    }, 1000);
  }, []);

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    // console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  // Collapse states:
  const [expanded, setexpanded] = useState(0);

  return (
    <PageWithScrollArrows id={'LandingPage1'}>
      {/* <div id="LandingPage" 
        // className="LandingPage"
        > */}
      <Box sx={{ position: 'relative', width: '100vw', margin: 0, padding: 0 }}>
        <div style={{ height: 0 /*22 * windowInnerSize.height*/ }}>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 9,
                  padding: isPhone ? 1.5 : 3,
                }}
              >
                <img
                  src={PSLogoYellow}
                  alt=""
                  style={{
                    width: isPhone ? '40px' : '70px',
                    paddingRight: isPhone ? '4px' : '8px',
                    display: 'block',
                  }}
                />
                <Typography
                  variant="h5"
                  className={classes.textMonarcha}
                  sx={{ textTransform: 'none !important', width: 'fit-content' }}
                >
                  PassionStocks
                </Typography>
              </Box>
              <Typography
                className={classes.textOpenSans}
                variant={'subtitle1'}
                onClick={() => props.setIndex(1)}
                sx={{
                  height: 'fit-content',
                  marginRight: isPhone ? 2.5 : 4,
                  marginTop: isPhone ? 2.5 : 4,
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
              >
                Creator Agency
              </Typography>
            </div>
            <Creators__OpeningSlide />
          </div>{' '}
          <div>
            <Copy1A />
          </div>
          <div id="EstimateSlide">
            <EstimateSlide isPhone={isPhone} />
          </div>
          <div>
            <Copy1B />
          </div>
          <div>
            <div id="EstimateSlide">
              <PersonalBrandProcess isPhone={isPhone} />
            </div>
            <div>
              <Copy1C />
            </div>
            <div id="EstimateSlide">
              <WelcomeSlide isPhone={isPhone} />
            </div>
            <div>
              <Copy1 />
            </div>

            <Collapse in={expanded >= 1} timeout={2500}>
              <div>
                <OurMarketplace />
              </div>
              <div>
                <OneProductMarketplaceSteps />
              </div>
              <div>
                <Copy2 />
              </div>

              {/* ///////// */}

              <Collapse in={expanded >= 2} timeout={2500}>
                <div>
                  <Copy2A />
                </div>
                <div>
                  <Top4CreatorsGraph />
                </div>
                <div>
                  <Copy3 />
                </div>

                <Collapse in={expanded >= 3} timeout={2500}>
                  <div>
                    <ScalabilityDrivesSteps />
                  </div>
                  <div>
                    <Copy4 />
                  </div>
                  <div>
                    <TreeChart />
                  </div>
                  <div>
                    <Copy5A />
                  </div>

                  <Collapse in={expanded >= 4} timeout={2500}>
                    <div>
                      <Copy5B />
                    </div>

                    <Collapse in={expanded >= 5} timeout={2500}>
                      <div>
                        <Copy5 />
                      </div>

                      <Collapse in={expanded >= 6} timeout={2500}>
                        <div>
                          <Copy6A />
                        </div>
                      </Collapse>
                    </Collapse>
                  </Collapse>
                </Collapse>
              </Collapse>
            </Collapse>

            {expanded !== 6 && (
              <FindOutMore
                onClick={() => {
                  setexpanded(expanded + 1);
                }}
              />
            )}

            <div>
              <Copy8 />
            </div>
            <div>
              <JoinTheWaitlistSlide typePage={'creator'} />
            </div>
          </div>
          <Footer typePage="creator"/>
        </div>
        {/* </div> */}
      </Box>
    </PageWithScrollArrows>
  );
};
export function isValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default CreatorsPage;
