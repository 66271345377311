import { Box, Typography } from '@mui/material';
import Agency__OpeningSlide from './OpeningSlide';
import { makeStyles } from '@mui/styles';
import Agency__EstimateSlide from './Estimate';
import Agency__CompanyProcess from './AgencyCompanyProcess';
import Agency__Copy1 from './Copy1';
import Agency__Copy1A from './Copy1A';
import Agency__Copy2 from './Copy2';
import Agency__Copy3 from './Copy3';
import Agency__PSStations from './PSStations';
import Agency__Copy4 from './Copy4';
import Agency__Copy5 from './Copy5';
import JoinTheWaitlistSlide from '../common-slides/JoinTheWaitlist';
import Footer from '../common-slides/Footer';
import PageWithScrollArrows from '../../../components/buttons/PageWithScrollArrows';
import { useEffect, useState } from 'react';
import isMobileDevice from '../../../utiles/isMobileDevice';
import { useSelector } from 'react-redux';

import PSLogoYellow from '../../../images/ps-icon.png';
import { useLocation } from 'react-router-dom';
import store from '../../../store/store';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectNavigateBackLocation = (state) => state.navigateBackLocation;

export default function AgencyPage(props) {
  const classes = useStyles();

  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigateBackLocation = useSelector(selectNavigateBackLocation);

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    // console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  // navigate to the last position:
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (navigateBackLocation?.typePage === 'agency') {
        const scrollPositionUp = document.getElementById('AgencyPage');
        if (navigateBackLocation?.from === 'wishlist') {
          scrollPositionUp.scrollTo({
            top: scrollPositionUp.scrollHeight - windowInnerSize.height * 2, // windowInnerSize.height * 19,
          });
        }
        if (navigateBackLocation?.from === 'footer') {
          scrollPositionUp.scrollTo({
            top: scrollPositionUp.scrollHeight,
          });
        }
        store.dispatch({
          type: 'ui/navigateBackLocation/add',
          payload: null,
        });
      }
    }, 500);
  }, []);
  return (
    <PageWithScrollArrows id="AgencyPage">
      <Box sx={{ position: 'relative', width: '100vw', margin: 0, padding: 0 }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 9,
              padding: isPhone ? 1.5 : 3,
            }}
          >
            <img
              src={PSLogoYellow}
              alt=""
              style={{
                width: isPhone ? '40px' : '70px',
                paddingRight: isPhone ? '4px' : '8px',
                display: 'block',
              }}
            />
            <Typography
              variant="h5"
              className={classes.textMonarcha}
              sx={{ textTransform: 'none !important', width: 'fit-content' }}
            >
              PassionStocks
            </Typography>
          </Box>
          <Typography
            className={classes.textOpenSans}
            variant={'subtitle1'}
            onClick={() => props.setIndex(0)}
            sx={{
              height: 'fit-content',
              marginRight: isPhone ? 2.5 : 4,
              marginTop: isPhone ? 2.5 : 4,
              cursor: 'pointer',
              fontSize: '14px',
              zIndex: 100,
            }}
          >
            Creator
          </Typography>
        </div>
        <Agency__OpeningSlide isPhone={props.isPhone} />
        <Agency__Copy1A />
        <Agency__Copy1 />
        <Agency__EstimateSlide isPhone={props.isPhone} />
        <Agency__Copy2 />
        <Agency__CompanyProcess />
        <Agency__Copy3 />
        <Agency__PSStations />
        <Agency__Copy4 />
        <Agency__Copy5 />
        <JoinTheWaitlistSlide typePage={'agency'} />
        <Footer typePage={'agency'} />
      </Box>
      {/* </div> */}
    </PageWithScrollArrows>
  );
}
