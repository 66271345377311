import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy1(props) {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);

  useEffect(() => {
    const copy1aElement = document.getElementById('Copy1A');
    const calculatedMarginA = (window.innerHeight - copy1aElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const copy1bElement = document.getElementById('Copy1B');
    const calculatedMarginB = (window.innerHeight - copy1bElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
    const copy1cElement = document.getElementById('Copy1C');
    const calculatedMarginC = (window.innerHeight - copy1cElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy1A" style={{ margin: `${marginsA}px auto` }}>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          Personal brand
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5 }}
        >
          Is a
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1, marginBottom: 7 }}
          variant="h2"
        >
          lifetime asset.
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 5 }}
        >
          They can <span style={{ color: '#ffbf12' }}>ban you</span> from TikTok, <br />
          They can even <span style={{ color: '#ffbf12' }}>ban TikTok</span>,
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1, marginBottom: { xs: 1, sm: 0 } }}
        >
          But Personal brand
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 10 }}
        >
          can never be banned.
        </Typography>
      </div>

      <div id="Copy1B" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1 }}
        >
          So, What is <br />
          passionstocks?
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5 }}
        >
          Passionstocks is A
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 0 }}
        >
          One-stop shop{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5 }}
        >
          for content creators to{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 0 }}
        >
          Build & boost
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5 }}
        >
          their{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 1 }}
        >
          personal brand <br />
          as a tangible asset.{' '}
        </Typography>
       
      </div>

      <div id="Copy1C" style={{ margin: `${marginsC}px auto 0` }}>
        {' '}
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          Wondering how to
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          add Tangible value{' '}
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          to Your
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 7 }}
        >
          Personal brand Asset?
        </Typography>
       
      </div>
    </Container>
  );
}
