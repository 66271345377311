import { Container, Typography } from '@mui/material';

import ThankYouMentionIcon from '../../../../images/thank-you-mention-icon.svg';
// import ProcessSteps from '../../components/ProcessSteps';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, withWidth } from '@material-ui/core';
// import ScalabilityDrives__Versatility from './components/ScalabilityDrives__Versatility';
// import ScalabilityDrives__Scalability from './components/ScalabilityDrives__Scalability';
// import ScalabilityDrives__Valuation from './components/ScalabilityDrives__Valuation';
import ScalabilityDrives__mobile from './components/mobile';
import ScalabilityDrives__desktop from './components/desktop';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

const ScalabilityDrivesSteps = ({ width, ...props }) => {
  const classes = useStyles();

  return (
    <Box sx={{ height: 'fit-content', marginBottom: 0, marginTop: 10 }}>
      <Typography

        className={classes.textMonarcha}
        variant="h5"
        sx={{
          fontSize: width === 'xl' ? '1.9rem !important' : '1.8rem !important',
          textAlign: 'center', lineHeight: 1.1, color: '#ffbf12' }}
      >
        Scalability Drives Startup Valuation{' '}
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{ textAlign: 'center', lineHeight: 1.5, color: '#ffffff77' }}
      >
        monetization for startup valuation{' '}
      </Typography>
      <img
        src={ThankYouMentionIcon}
        alt="Graph by avatars"
        style={{ display: 'block', margin: '10px auto 35px', width: width==='xs'?'35vw' :'160px'}}
      />

      {width === 'xs' ? <ScalabilityDrives__mobile /> : <ScalabilityDrives__desktop />}
    </Box>
  );
};

export default withWidth()(ScalabilityDrivesSteps);
