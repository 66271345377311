import { Box, Container, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import NumberedCirclesButtons from '../../../../components/steps/NumberedCirclesButtons';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';

import AvatarsGraphs from '../../../../images/avatars-graphs.svg';
import ProcessSteps from '../../components/ProcessSteps';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import OneProduct__StoryShoutoutOI from './components/desktop/OneProduct__StoryShoutoutOI';
import OneProduct__AutomatedFormat from './components/desktop/OneProduct__TextAndTagging';
import OneProduct__PricingMethod from './components/desktop/OneProduct__PricingMethod';
import { withWidth } from '@material-ui/core';
import OneProductMarketplaceSteps__desktop from './components/desktop';
import OneProductMarketplaceSteps__mobile from './components/mobile';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

const OneProductMarketplaceSteps = ({ width, ...props }) => {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  return (
    <Box sx={{ height: 'fit-content', marginBottom: 0 }}>
      <div>
        <Typography
          className={classes.textMonarcha}
          variant="h5"
          sx={{
            position: 'relative',
            display: 'block',
            textAlign: 'center',
            color: index === 2 ? '#ffffff44' : '#ffbf12',
            lineHeight: 1.3,
            marginTop: 2,
            marginBottom: 3,
            fontSize: width === 'xs' ? '14px' : '',
          }}
        >
          3 steps to sell shoutouts on passionstocks
          <br />
          <span style={{ color: index === 2 ? '#ffbf12' : '#ffffff44' }}>
            & Auto-Deliver them on your Instagram
          </span>
        </Typography>
        <NumberedCirclesButtons
          variant={width === 'xs' ? 'small' : ''}
          count={3}
          checkedIndex={index}
          setIndex={setIndex}
        />
      </div>
      <SwipeableViews
        style={{ overflow: 'visible !important' }}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <div
          style={{
            margin: '30px auto 0',
            maxWidth: '90vw',
            height:
              width === 'xs'
                ? ['290px', '420px', '600px'][index]
                : ['430px', '670px', '840px'][index],
          }}
        >
          <div>
            <Typography
              className={classes.textMonarcha}
              variant="h6"
              sx={{
                color: '#ffbf12',
                textAlign: 'center',
                marginBottom: 1,
                fontSize: width === 'xs' ? '12px' : '',
                minheight: 1,
                display: 'block',
                transform: 'translateZ(0)',
              }}
            >
              1. Link Your Instagram & start selling shoutouts{' '}
            </Typography>
            <Typography
              className={classes.textOpenSans}
              variant="body"
              sx={{
                display: 'block',
                color: '#ffffff77',
                textAlign: 'center',
                fontWeight: 'lighter',
                fontSize: width === 'xs' ? '9px' : '',
              }}
            >
              join passionstocks, link your instagram, and let buyers find, purchase, and receive
              your shoutouts automatically.
              <br />
              once you link your instagram, your shoutouts will instantly appear on the marketplace,
              ready to sell.
              <br />
              <span
                style={{ paddingTop: '8px', fontWeight: 'bold', lineHeight: '10px !important' }}
              >
                below, you can see how story shoutouts appear on the marketplace.
              </span>
            </Typography>
            <img
              src={'/images/digital-card-details.png'}
              alt="Thank You Mention Digital Card"
              style={{
                display: 'block',
                margin: width === 'xs' ? '15px auto 0' : '35px auto 0',
                position: 'relative',
                width: width === 'xs' ? '270px' : '630px',
                zIndex: 6,
              }}
            />{' '}
            <Typography
              className={classes.textMonarcha}
              variant="subtitle1"
              sx={{
                color: '#ffbf12',
                textAlign: 'center',
                lineHeight: 1.3,
                marginTop: 5,
                marginBottom: 5,
                fontSize: width === 'xs' ? '12px' : '',
              }}
            >
              Buyers can resell your shoutouts{width === 'xs' && <br />} if you allow them, <br />
              <span style={{ color: '#ffffff66' }}>
                boosting your journey to a {width === 'xs' && <br />}$1B personal brand valuation.
                Learn more{' '}
              </span>{' '}
            </Typography>
          </div>
        </div>
        <div
          style={{
            margin: '30px auto 0',
            maxWidth: '90vw',
            height:
              width === 'xs'
                ? ['290px', '420px', '600px'][index]
                : ['430px', '670px', '840px'][index],
            minheight: 1,
            display: 'block',
            transform: 'translateZ(0)',
          }}
        >
          <Typography
            className={classes.textMonarcha}
            variant="h6"
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              marginBottom: 1,
              fontSize: width === 'xs' ? '12px' : '',
            }}
          >
            2. place an order{' '}
          </Typography>
          <Typography
            className={classes.textOpenSans}
            variant="body"
            sx={{
              display: 'block',
              color: '#ffffff77',
              textAlign: 'center',
              fontSize: width === 'xs' ? '9px' : '',
            }}
          >
            based on your set preferences for timing and frequency,
            <br /> buyers can see the estimated delivery time for their shoutout
            {width === 'xs' && <br />} on your instagram story,
            <br /> and complete their purchase instantly—no extra effort needed from you. <br />
            <span style={{ paddingTop: '8px', fontWeight: 'bold' }}>
              below, you can see how the checkout process looks for buyers.{' '}
            </span>
          </Typography>
          <img
            src={'/images/review-purchase-details.png'}
            alt="Thank You Mention Digital Card"
            style={{
              display: 'block',
              margin: width === 'xs' ? '15px auto 0' : '35px auto 0',
              position: 'relative',
              width: width === 'xs' ? '270px' : '520px',
              zIndex: 6,
            }}
          />{' '}
          <Typography
            className={classes.textMonarcha}
            variant="subtitle1"
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              lineHeight: 1.3,
              marginTop: 5,
              marginBottom: 5,
              fontSize: width === 'xs' ? '12px' : '',
            }}
          >
            Buyers can resell your shoutouts{width === 'xs' && <br />} if you allow them, <br />
            <span style={{ color: '#ffffff66' }}>
              boosting your journey to a{width === 'xs' && <br />} $1B personal brand valuation.
              Learn more{' '}
            </span>{' '}
          </Typography>
        </div>
        <div
          style={{
            margin: '30px auto 0',
            maxWidth: '90vw',
            height:
              width === 'xs'
                ? ['290px', '420px', '600px'][index]
                : ['430px', '670px', '840px'][index],
            minheight: 1,
            display: 'block',
            transform: 'translateZ(0)',
          }}
          id="safariIssue"
        >
          <Typography
            className={classes.textMonarcha}
            variant="h6"
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              marginBottom: 1,
              fontSize: width === 'xs' ? '12px' : '',
            }}
          >
            3. Shoutout Automatic Delivery{' '}
          </Typography>
          <Typography
            className={classes.textOpenSans}
            variant="body"
            sx={{
              display: 'block',
              color: '#ffffff77',
              textAlign: 'center',
              fontSize: width === 'xs' ? '9px' : '',
            }}
          >
            shoutouts are delivered automatically to buyers on your instagram story.
            <br /> buyers can also share the shoutout to their own story, making the process
            seamless and engaging.
            <br />
            <span style={{ paddingTop: '8px', fontWeight: 'bold' }}>
              below, you can see how the story shoutout will appear on the creator’s instagram
              story.{' '}
            </span>
          </Typography>
          <img
            src={'/images/shoutout-thank-you.png'}
            alt="Thank You Mention Digital Card"
            style={{
              display: 'block',
              margin: width === 'xs' ? '15px auto 0' : '35px auto 0',
              position: 'relative',
              width: width === 'xs' ? '230px' : '380px',
              zIndex: 6,
            }}
          />{' '}
          <Typography
            className={classes.textMonarcha}
            variant="subtitle1"
            sx={{
              color: '#ffbf12',
              textAlign: 'center',
              lineHeight: 1.3,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Buyers can resell your shoutouts if you allow them, <br />
            <span style={{ color: '#ffffff66' }}>
              boosting your journey to a{width === 'xs' && <br />} $1B personal brand valuation.
              Learn more{' '}
            </span>{' '}
          </Typography>
        </div>
      </SwipeableViews>
      <JoinTheWaitlistButton />

      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          fontSize: width === 'xl' ? '1.75rem !important' : '1.75rem !important',
          textAlign: 'center',
          lineHeight: 1.3,
          color: '#ffbf12',
          marginBottom: { xs: 1, sm: 0 },
          marginTop: 12,
        }}
      >
        Passionstocks {width === 'xs' && <br />}One-product marketplace {width === 'xs' && <br />}{' '}
        for shoutouts{' '}
      </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{ textAlign: 'center', lineHeight: 1.3, color: '#ffffff77' }}
      >
        where product value comes entirely {width === 'xs' && <br />}from the personal brand
      </Typography>
      <img
        src={AvatarsGraphs}
        alt="Graph by avatars"
        style={{ display: 'block', margin: '10px auto', width: width === 'xs' ? `70vw` : '25vw' }}
      />

      {width === 'xs' ? (
        <OneProductMarketplaceSteps__mobile />
      ) : (
        <OneProductMarketplaceSteps__desktop />
      )}
    </Box>
  );
};

export default withWidth()(OneProductMarketplaceSteps);
