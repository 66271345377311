import React from 'react';
import { Button, Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

const smallButtonStyle = {
  padding: 0,
  minWidth:36,
  height: 35,
  fontSize: '18px',
};
const mediumButtonStyle = {
  minWidth:  45,
  height: 45,
  fontSize: '22px',
};
const NumberedCircleButtons = ({ variant, count, checkedIndex, setIndex }) => {
  const classes = useStyles();

  return (
    <Box display="flex" gap={variant === 'small' ? 4 : 8} justifyContent="center">
      {Array.from({ length: count }, (_, index) => (
        <Button
          className={classes.textMonarcha}
          key={index}
          variant="contained"
          disableElevation
          sx={{
            ...(variant === 'small' ? smallButtonStyle : mediumButtonStyle),
            borderRadius: '50%',
            fontWeight: 'bold',
            backgroundColor: index === checkedIndex ? '#ffbf12' : '#707070',
            color: index === checkedIndex ? 'black' : '#00000099',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s, transform 0.2s',
            '&:hover': {
              backgroundColor: index === checkedIndex ? '#ffbf12' : '#ffffff77',
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => {
            setIndex(index);
          }}
        >
          {index + 1}
        </Button>
      ))}
    </Box>
  );
};

export default NumberedCircleButtons;
