
export default function navigateBackLocation(state = { page: '', from: ''/**/, typePage: 'creator' }, action) {
    switch (action.type) {
        case 'ui/navigateBackLocation/add': {
            return action.payload
        }
        case 'ui/navigateBackLocation/delete': {
            return false
        }

        default:
            return state
    }
}