import { combineReducers } from "redux";

import windowInnerSize from "./reducers/windowInnerSize";
import latestVersionSaved from "./reducers/latestVersionSaved";
import isDisplayedOpeningView from "./reducers/isDisplayedOpeningView";
import waitlistSelect from "./reducers/waitlistSelect";
import moneyCalculatorValues from "./reducers/moneyCalculatorValues";
import navigateBackLocation from "./reducers/navigateBackLocation";
const rootReducer = combineReducers({
  windowInnerSize: windowInnerSize,
  latestVersionSaved: latestVersionSaved,
  isDisplayedOpeningView: isDisplayedOpeningView,
  waitlistSelect: waitlistSelect,
  moneyCalculatorValues: moneyCalculatorValues,
  navigateBackLocation: navigateBackLocation
});

const exportReducer = (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);

export default exportReducer;
// export default rootReducer
