import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy5A() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);

  useEffect(() => {
    const Copy5AAElement = document.getElementById('Copy5AA');
    const calculatedMarginA = (window.innerHeight - Copy5AAElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{}}>
      <div id="Copy5AA" style={{ margin: `${marginsA/3*2}px auto 60px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 0 }}
        >
          So, What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Your Community?
        </Typography>
      </div>
    </Container>
  );
}
