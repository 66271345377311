import { withWidth } from '@material-ui/core';
import { Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const selectWindowInnerSize = (state) => state.windowInnerSize;

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

const JoinTheWaitlistButton = ({ width, ...props }) => {
  let { variant, onClick, pageType } = props;
  if (width !== 'xl') {
    variant = 'small';
  }
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      if (pageType === 'agency') {
        const element = document.getElementById('AgencyPage');
        element.scrollTo({
          top: element.scrollHeight - windowInnerSize.height * 2,
        });
      } else {
        const element = document.getElementById('LandingPage1');

        element.scrollTo({
          top: element.scrollHeight - windowInnerSize.height * 2,
        });
      }
    }
  };
  return (
    <Container>
      <Button
        className={classes.textOpenSans}
        sx={{
          //   position: 'absolute',
          //   left: '50%',
          //   bottom: 0,
          //   transform: 'translate(-50%,0)',
          textTransform: 'none',
          color: 'black',
          fontSize: variant === 'small' ? '14px' : '20px',
          lineHeight: variant === 'small' ? '14px' : '20px',
          padding: variant === 'small' ? '9px 90px' : '14px 95px',
          background: '#ffbf12',
          borderRadius: '50px',
          margin: '0px auto 0',
          display: 'block',
          '&:hover': { background: '#ffbf12' },
        }}
        // variant="outlined"
        onClick={handleClick}
      >
        Join the Waitlist
      </Button>
    </Container>
  );
};

export default withWidth()(JoinTheWaitlistButton);
