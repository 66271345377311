import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CreatorsPage from './creators-slides/index';
import AgencyPage from './agency-slides';
import { withWidth } from '@material-ui/core';
import { useSelector } from 'react-redux';

const selectNavigateBackLocation = (state) => state.navigateBackLocation;

function LandingPageLayout({ width }) {
  const navigateBackLocation = useSelector(selectNavigateBackLocation);

  
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
  };


  useEffect(() => {
    if (navigateBackLocation?.typePage === 'agency') {
      setIndex(1);
    }
  }, []);

  return (
    <Box>
      <SwipeableViews disabled index={index} onChangeIndex={handleChangeIndex}>
        <Box sx={{ height: '100vh', width: '100vw' }}>
          <CreatorsPage setIndex={setIndex} />
        </Box>
        <Box sx={{ height: '100vh', width: '100vw' }}>
          <AgencyPage setIndex={setIndex} isPhone={width === 'xs'} />
        </Box>
      </SwipeableViews>
    </Box>
  );
}
export default withWidth()(LandingPageLayout);
