import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
}));

export default function ScalabilityDrives__Scalability(props) {
  const classes = useStyles();

  return (
    <Box sx={{ width: '6vw', height: '100%', paddingTop: 0 }}>
      <Typography
        className={props.fontStyle}
        variant="subtitle1"
        sx={{
          position: 'relative',
          width: 'fit-content',
          color: '#ffffff44',
          lineHeight: 1.3,
          textAlign: 'center',
          marginRight: 'auto !important',
          marginLeft: 'auto !important',
          marginTop: 2,
          marginBottom: 0,
          fontSize: '11px !important',
        }}
      >
        <span style={{ color: '#ffbf12' }}>
          {' '}
          Shoutout sales on passionstocks are scalable because <br />
        </span>
        it's <span style={{ color: '#ffbf12' }}>digital</span>,{' '}
        <span style={{ color: '#ffbf12' }}>automated</span>,{' '}
        <span style={{ color: '#ffbf12' }}>templated</span>, and easily
        <span style={{ color: '#ffbf12' }}> consumable</span>.
        <br />
        <br />
        allowing for <br/><span style={{ color: '#ffbf12' }}>easy replication & distribution </span>{' '}
        <br />
        without additional effort.<br/>
        <img
          src={'/images/scalability1.svg'}
          alt=""
          style={{
            width: '60px',
            padding:'10px'
          }}
        />
      </Typography>
      {/* </div> */}
      <Typography
        className={props.fontStyle}
        variant="subtitle1"
        sx={{
          color: '#ffffff44',
          lineHeight: 1.3,
          textAlign: 'center',
          marginTop: 0,
          marginBottom: 2,
          fontSize: '11px !important',
        }}
      >
        Shoutouts are a one-time consumable digital service, <br />
        simplifying transactions by reducing* the legal<br/> and accounting complexities <br />
        of physical goods, ongoing commitments, or crypto<br/> coins & assets.{' '}
      </Typography>
      <Typography
        className={props.fontStyle}
        variant="subtitle1"
        sx={{
          color: '#ffffff44',
          lineHeight: 1.3,
          textAlign: 'center',
          margin: 0,
          marginLeft: 1,
          fontSize: '11px !important',
        }}
      >
        By <span style={{ color: '#ffbf12' }}>default</span>, shoutouts are{' '}
        <span style={{ color: '#ffbf12' }}>capped</span> at 1% of your total followers<br/> at a time,{' '}
        <br />
        with a maximum of 250 million to{' '}
        <span style={{ color: '#ffbf12' }}>optimize supply & demand</span>.
        <br />
        <br />
        <span style={{ color: '#ffbf12' }}>You</span> can{' '}
        <span style={{ color: '#ffbf12' }}>adjust</span> this{' '}
        <span style={{ color: '#ffbf12' }}>limit</span> based on{' '}
        <span style={{ color: '#ffbf12' }}>your sales strategy</span>.
      </Typography>{' '}
      <img src={'/images/scalability2.svg'} style={{margin:'0 auto',display:'block', height: '80px',padding:'10px' }} />
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{
          position: 'relative',
          display: 'block',
          fontSize: '8px',
          color: '#ffffff44',
          lineHeight: 1.2,
          width: '92%',
          marginRight: 'auto !important',
          marginLeft: 'auto !important',
        }}
      >
        *PassionStocks does not provide legal, financial, or accounting advice. Always consult a
        professional or use PassionStocks’ recommended third-party services for trademarks,
        contracts, or other legal and financial matters.
        <br />
        These recommendations are not endorsements but suggestions for your convenience.
      </Typography>
    </Box>
  );
}
