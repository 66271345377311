import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TotalStatsExampls from '../../../../../../../images/total-stats-example.png';
import Marketplace from '../../../../../../../images/marketplace.svg';
import DoubleArrows from '../../../../../../../images/double-arrow-down-white.png';
import { useState } from 'react';
import ImageGallery from '../../../../../components/ImageGallery';
import SwipeableViews from 'react-swipeable-views';

export default function OneProduct__PricingMethod(props) {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
    // console.log(i);
  };

  // ImageGallery without thumbnails - should maintain 'open' state on the parent component
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '50vw', height: '100%', paddingTop: 4 }}>
      {index === 0 && (
        <Typography
          className={props.fontStyle}
          sx={{
            position: 'relative',
            left: '-45px',
            display: 'block',
            color: '#ffffff44',
            width: '240px',
            lineHeight: 1.2,
            fontSize: '11px !important',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          On PassionStocks, <span style={{ color: '#ffbf12' }}>shoutout pricing</span> adjusts{' '}
          dynamically, <span style={{ color: '#ffbf12' }}>similar to flight tickets or Uber</span>,{' '}
          <br />
          based on <span style={{ color: '#ffbf12' }}>supply & demand</span> <br />
          - limited availability of shoutouts.
          <br />
          <br />
        </Typography>
      )}

      {index === 0 && (
        <Typography
          className={props.fontStyle}
          sx={{
            position: 'relative',
            left: '7%',
            display: 'block',
            color: '#ffffff44',
            width: '100%',
            lineHeight: 1.2,
            fontSize: '11px !important',
            textAlign: 'center',
            marginBottom: 2,
          }}
        >
          <span style={{ color: '#ffbf12' }}>Creators</span> on PassionStocks can
          <br />
          <span style={{ color: '#ffbf12' }}>
            create custom pricing methods <br />
            for shoutouts,
            <br />
            <br />
            controlling how prices fluctuate
          </span>{' '}
          <br />
          with options to <br />
          set minimum & maximum caps.
        </Typography>
      )}

      {index == 1 && (
        <Typography
          className={props.fontStyle}
          sx={{
            position: 'relative',
            left: '7%',
            display: 'block',
            color: '#ffffff44',
            width: '100%',
            lineHeight: 1.2,
            fontSize: '11px !important',
            textAlign: 'center',
            marginBottom: 4,
            marginTop:12
          }}
        >
          <span style={{ color: '#ffbf12' }}> PassionStocks Market Price</span> <br />
          helps buyers see if a shoutout price <br />
          is fair, high, or low, based on <br />
          <span style={{ color: '#ffbf12' }}>social media & market trends.</span>
        </Typography>
      )}

      <SwipeableViews style={{ width: '100%' }} index={index} onChangeIndex={handleChangeIndex}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={TotalStatsExampls}
            style={{ width: '39.25vw', display: 'block', margin: '0 auto' }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Marketplace}
            style={{ width: '110px', display: 'block', margin: 'auto 0% auto 10% ' }}
            // onClick={() => {
            //   setOpen(true);
            // }}
          />
        </div>
      </SwipeableViews>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '9vw',
          opacity: 0.44,
          cursor: 'pointer',
          margin: '25px auto 0',
        }}
        onClick={() => {
          setIndex((index + 1) % 2);
        }}
      >
        <img
          src={DoubleArrows}
          alt="Click to view card details"
          style={{ width: '18px', transform: 'rotate(-90deg)' }}
        />
        <Typography
          className={props.fontStyle}
          sx={{ fontSize: '12px', textAlign: 'center', lineHeight: 1.2, marginTop: 0.5 }}
        >
          {index + 1}/2
        </Typography>
      </div>
      {/* </div>{' '} */}

      <ImageGallery images={[TotalStatsExampls]} open={open} setOpen={setOpen} index={index} />
    </Box>
  );
}
