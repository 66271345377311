import { Box, Container, Typography } from '@mui/material';

import AgencyTo1B from '../../../../images/agency-to-1b.png';
import ProcessSteps from '../../components/ProcessSteps';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AgencyCompanyAnimate from './component/Animate/desktop';
import { withWidth } from '@material-ui/core';
import StepsContent from './component/StepsContent';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%,0)',
    width: 'max-content',
    textAlign: 'center',
    // fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      // fontSize: '13px !important',
    },
    [theme.breakpoints.up('md')]: {
      // fontSize: '14px !important',
    },
    [theme.breakpoints.up('lg')]: {
      // fontSize: '16px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      // fontSize: '20px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

function Agency__CompanyProcess({ width, ...props }) {
  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 65, sm: 180, md: 190, lg: 190, xl: 230 };
  useEffect(() => {
    setScale(sizes[width]);
  }, [width]);

  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        color: index === 0 ? '#ffbf12' : '909090',
        width: { xs: '22vw', sm: 'max-content' },
        // height: { xs: 4, sm: 0 },
        top: { xs: '5px', sm: '0' },
        left: { xs: '49px', sm: '16px' },
        lineHeight: 1.2,
      }}
    >
      Creator
      <br />
      Agency
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        color: index === 1 ? '#ffbf12' : '909090',
        width: { xs: '30vw', sm: 'max-content' },
        top: { xs: '-23px', sm: '0' },
        left: { xs: '10px', sm: '16px' },
        lineHeight: 1.2,
      }}
    >
      PassionStocks
      <br />
      Account
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        color: index === 2 ? '#ffbf12' : '909090',
        width: { xs: '29vw', sm: 'max-content' },
        top: { xs: '-33px', sm: '0' },
        left: { xs: '13px', sm: '16px' },
        lineHeight: 1.2,
      }}
    >
      Creator Agency
      <br />
      Value appreciation
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        color: index === 3 ? '#ffbf12' : '909090',
        width: { xs: '35vw', sm: 'max-content' },
        top: { xs: '-55px', sm: '0' },
        left: { xs: '-16px', sm: '16px' },
        lineHeight: 1.2,
      }}
    >
      $1B creator
      <br />
      agency
    </Typography>,
  ];

  const stepDescription = [
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: { xs: '', sm: 'absolute' },
        top: { xs: '', sm: '45%' },
        left: { xs: '', sm: '128%' },
        transform: { xs: '', sm: 'translate(-50%,-50%)' },
        fontSize: { xs: '12px', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      Whether you{' '}
      <span style={{ color: '#ffbf12' }}>
        already manage <br />
        creators or *looking to start out,
        <br />
        we’ll teach you
      </span>{' '}
      how to find creators <br />
      and manage their personal brands.
      <br />
      <br />
      <span style={{ color: '#ffbf12' }}>
        You can build a $1B creator agency <br />
        for personal brands—
        <br />
        no capital, company registration, <br />
        or trademark needed to get started.
      </span>
    </Typography>,
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: { xs: 'absolute', sm: 'absolute' },
        top: { xs: scale * 1.85, sm: '50%' },
        left: { xs: '50%', sm: '50%' },
        transform: { xs: 'translate(-50%,0)', sm: 'translate(-50%,-50%)' },
        fontSize: { xs: '12px', sm: scale * 0.09 },
        lineHeight: 1.2,
      }}
    >
      Open an
      <span style={{ color: '#ffbf12' }}>
        {' '}
        Agency Account
        <br />
        On passionstocks
      </span>{' '}
      to manage <br />
      Creators’ personal brands <br />
      and <span style={{ color: '#ffbf12' }}>Set a management fee </span>
      <br />
      from their <br />
      <span style={{ color: '#ffbf12' }}>Shoutout sales revenue.</span>
    </Typography>,
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: 'absolute',
        top: { xs: '-125%', sm: '-10%' },
        left: { xs: '-25%', sm: '50%' },
        transform: { xs: '', sm: 'translate(-50%,0%)' },
        fontSize: { xs: '12px', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      When you <br />
      <span style={{ color: '#ffbf12' }}>Add creators to your agency,</span> <br />
      your creator agency
      <br />
      <span style={{ color: '#ffbf12' }}>Value goes up.</span>
    </Typography>,
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: 'absolute',
        top: { xs: '-120%', sm: '5%' },
        left: { xs: '-35%', sm: '50%' },
        transform: { xs: '', sm: 'translate(-50%,0%)' },
        fontSize: { xs: '12px', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      You can achieve a $1B valuation <br />
      <span style={{ color: '#ffbf12' }}>on your own</span> or{' '}
      <span style={{ color: '#ffbf12' }}>with other creator Agencies.</span>
    </Typography>,
  ];
  return (
    <Box
      sx={{
        height: { xs: scale * 11, sm: index === 2 ? scale * 4 : scale * 3.25 },
      }}
    >
      <Box>
        <Typography
          className={classes.textMonarcha}
          variant="h5"
          sx={{
            fontSize: width === 'xl' ? '1.9rem !important' : '1.75rem !important',
            textAlign: 'center',
            lineHeight: 1.3,
            marginBottom: { xs: 1, sm: 0 },
            color: '#ffbf12',
          }}
        >
          Turn your creator agency into $1B startup company{' '}
        </Typography>
        <Typography
          className={classes.textOpenSans}
          variant="subtitle1"
          sx={{
            textAlign: 'center',
            lineHeight: 1.3,
            color: '#ffffff77',
          }}
        >
          it’s not easy, but with our ecosystem, it’s possible.{' '}
        </Typography>
        <img
          src={AgencyTo1B}
          alt="Agency to $1B"
          style={{ display: 'block', margin: '20px auto', width: `${scale * 1.5}px` }}
        />
      </Box>

      <StepsContent
        index={index}
        setIndex={setIndex}
        stepDescription={stepDescription}
        stepsNames={stepsNames}
        scale={scale}
      />
    </Box>
  );
}
export default withWidth()(Agency__CompanyProcess);
