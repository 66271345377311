import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy5B() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);

  useEffect(() => {
    const Copy5BAElement = document.getElementById('Copy5BA');
    const calculatedMarginA = (window.innerHeight - Copy5BAElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );

    const Copy5BBElement = document.getElementById('Copy5BB');
    const calculatedMarginB = (window.innerHeight - Copy5BBElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{}}>
      <div id="Copy5BA" style={{ margin: `20px auto ${marginsA}px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 0 }}
        >
          They get
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1, marginBottom: 3 }}
        >
          Story Shoutouts{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 0 }}
        >
          & the <span style={{ color: '#ffbf12' }}>opportunity</span> to become{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 0 }}
        >
          Business Partners
          <br />
          of your personal brand
        </Typography>
      </div>

      <div id="Copy5BB" style={{ margin: `${marginsB}px auto 60px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Wondering how to let
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Your Community become <br />
          business partners <br />
          of your personal brand?
        </Typography>
      </div>
    </Container>
  );
}
