import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import ImageGallery from '../../../../../components/ImageGallery';

export default function ScalabilityDrives__Versatility(props) {
  return (
    <FadeIn>
      <Container>
        {/* <div style={{ width: '90vw', width: '800px'}}> */}
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.1,
            textAlign: 'center',
            margin: 0,
            marginLeft: 1,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#ffbf12' }}> Shoutout campaigns</span> let you{' '}
          <span style={{ color: '#ffbf12' }}>
            sell Shoutouts for different purposes, <br />
            making shoutout sales more scalable.
          </span>
          <br />
          —whether to fund your next venture, open a coffee shop, launch a fashion line,
          <br /> or support a non-profit.
          <br />
          <br /> They{' '}
          <span style={{ color: '#ffbf12' }}>
            highlight your sales skills and the strength of your personal brand
          </span>
          , <br />
          with{' '}
          <span style={{ color: '#ffbf12' }}>each successful campaign adding tangible value </span>
          <br />
          and showcasing your brand’s influence.
        </Typography>

        <ImageGallery
          thumbnail
          flexDirection={'column'}
          sizes={['85%', '100%', '85%', '85%']}
          images={[
            '/images/scalability-drives-versatility-ex1.png',
            '/images/scalability-drives-versatility-ex2.png',
            '/images/scalability-drives-versatility-ex3.png',
            '/images/scalability-drives-versatility-ex4.png',
          ]}
        />
        {/* </div> */}
      </Container>
    </FadeIn>
  );
}
