import { Box, Container, Typography } from '@mui/material';
import PSLogoYellow from '../../../../images/ps-icon.png';
import { makeStyles } from '@mui/styles';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import { withWidth } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));
const Agency__OpeningSlide = ({ width, ...props }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      position={"relative"}
    >
      <Container sx={{ marginBottom: width !== 'xl' ? 5 : 7 }}>
        <Typography
          variant={width === 'xs' ? 'h2' : 'h2'}
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: width === 'xs' ? 1.15 : 1.15, marginBottom: width === 'xs' ? 2 : 3 }}
        >
          Manage <br />
          $1B personal brand creators
          {/* {props.isPhone && <br />} */}
        </Typography>
        <Typography
          variant={width === 'xs' ? 'h5' : 'h4'}
          className={classes.textMonarcha}
          sx={{ color: '#ffffff44', lineHeight: width === 'xs' ? 1.4 : 1.1 }}
        >
          Help creators achieve a $1B personal brand, <br />
          individually or collectively{' '}
        </Typography>
      </Container>{' '}
      <Box sx={{ position: 'absolute', bottom: '70px' }}>
        <JoinTheWaitlistButton pageType={'agency'} />
      </Box>
    </Box>
  );
};

export default withWidth()(Agency__OpeningSlide);
