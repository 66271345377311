import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy5() {
  const classes = useStyles();

  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);
  const [marginsD, setmarginsD] = useState(0);

  useEffect(() => {
    const Copy5BElement = document.getElementById('Copy5B');
    const calculatedMarginB = (window.innerHeight - Copy5BElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
    const Copy5CElement = document.getElementById('Copy5C');
    const calculatedMarginC = (window.innerHeight - Copy5CElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );
    const Copy5DElement = document.getElementById('Copy5D');
    const calculatedMarginD = (window.innerHeight - Copy5DElement.clientHeight) / 2;
    setmarginsD(
      calculatedMarginD > window.innerHeight / 4 ? calculatedMarginD : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{}}>
      <div id="Copy5B" style={{ margin: `0 auto ${marginsB}px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          You have the <span style={{ color: '#ffbf12' }}>option</span> to <br />
          allow <span style={{ color: '#ffbf12' }}>certain buyers</span> to{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          resell your shoutouts
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          on your behalf at higher prices, <br />
          giving them the opportunity <br />
          to act as resellers.{' '}
        </Typography>
      </div>
      <div id="Copy5C" style={{ margin: `${marginsC}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Wondering how to
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          optimize your <br />
          sales & resales?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          By Controlling{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          shoutout <br />
          Supply & demand{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.3, marginBottom: 5 }}
        >
          ,to drive value growth <br />- for you & your partners.{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1, marginBottom: 1 }}
        >
          & Including{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Packages & Perks
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          with your shoutout sales, <br />
          offering them based <br />
          on the quantity purchased.{' '}
        </Typography>
      </div>

      <div id="Copy5D" style={{ margin: `${marginsD}px auto 60px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Businesses & brands?
        </Typography>
      </div>
    </Container>
  );
}
