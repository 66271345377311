import { Box, Container, Typography } from '@mui/material';

import SocialTo1B from '../../../../images/social-to-1b.svg';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { withWidth } from '@material-ui/core';
import StepsContent from './component/StepsContent';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  stepName: {
    position: 'absolute',
    top: '0%',
    textAlign: 'center',
    // fontSize: '13px !important',
    color: '#909090',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      // fontSize: '14px !important',
      left: '50%',
      transform: 'translate(-50%,0)',
    },
    [theme.breakpoints.up('md')]: {
      // fontSize: '14px !important',
    },
    [theme.breakpoints.up('lg')]: {
      // fontSize: '14px !important',
      lineHeight: '1.2 !important',
    },
    [theme.breakpoints.up('xl')]: {
      // fontSize: '14px !important',
      lineHeight: '1.2 !important',
    },
  },
}));

function PersonalBrandProcess({ width, ...props }) {
  const [scale, setScale] = useState(0);
  // console.log(width);
  const sizes = { xs: 165, sm: 180, md: 190, lg: 190, xl: 230 };
  useEffect(() => {
    setScale(sizes[width]);
  }, [width]);

  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const stepsNames = [
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        position: 'absolute',
        color: index === 0 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        // height: { xs: 4, sm: 0 },
        top: { xs: '35%', sm: '0' },
        right: { xs: '14%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '25px', sm: '0' },

        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(0);
      }}
    >
      Content <br />
      Creator
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        position: 'absolute',

        color: index === 1 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '35%', sm: '0' },
        right: { xs: '20%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '10px', sm: '0' },

        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(1);
      }}
    >
      Passionstocks
      <br />
      Account
    </Typography>,
    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        position: 'absolute',
        color: index === 2 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '15px', sm: '0' },

        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(2);
      }}
    >
      Trademark
    </Typography>,

    <Typography
      className={`${classes.textMonarcha} ${classes.stepName}`}
      variant="subtitle2"
      sx={{
        position: 'absolute',
        color: index === 3 ? '#ffbf12' : '909090',
        width: { xs: 'max-content', sm: 'max-content' },
        top: { xs: '50%', sm: '0' },
        right: { xs: '6%', sm: '0' },
        transform: { xs: 'translate(0,-50%)', sm: 'translate(-50%,0)' },
        paddingRight: { xs: '15px', sm: '' },
        lineHeight: 1.2,
      }}
      onClick={() => {
        setIndex(3);
      }}
    >
      $1B social media <br />
      Personal brand
    </Typography>,
  ];

  const stepDescription = [
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: { xs: '', sm: 'absolute' },
        top: { xs: '', sm: '43%' },
        left: { xs: '', sm: '110%' },
        transform: { xs: '', sm: 'translate(-50%,-50%)' },
        fontSize: { xs: '12px', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      As a <span style={{ color: '#ffbf12' }}>content creator</span>, <br />
      you can build a <br />
      <span style={{ color: '#ffbf12' }}>$1 billion personal brand</span> {width === 'xs' && <br />}
      startup company
      <br />
      On PassionStocks. <br />
      <br />
      You can{' '}
      <span style={{ color: '#ffbf12' }}>
        Start up <br />
        without capital, {width === 'xs' && <br />} company registration,
        <br /> or trademark {width === 'xs' && <br />}
      </span>{' '}
      by leveraging our ecosystem.
    </Typography>,
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: 'absolute',
        top: { xs: '-148%', sm: '-3%' },
        left: { xs: '5%', sm: '50%' },
        transform: { xs: '', sm: 'translate(-50%,0%)' },
        fontSize: { xs: '12px', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      When your
      <br />
      <span style={{ color: '#ffbf12' }}>
        Social media engagement <br />
        goes up
      </span>
      , <br />
      your personal brand's
      <br /> <span style={{ color: '#ffbf12' }}>value goes up</span>.
    </Typography>,
    <Typography
      className={classes.textMonarcha}
      sx={{
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        position: 'absolute',
        // top: scale * 0.66,
        // right: -scale * 1.8,
        // fontSize: scale * 0.1,
        top: { xs: '-60%', sm: '60%' },
        left: { xs: '-28%', sm: '50%' },
        transform: { xs: '', sm: 'translate(-50%,-50%)' },
        fontSize: { xs: '12px ', sm: scale * 0.1 },
        lineHeight: 1.2,
      }}
    >
      By <span style={{ color: '#ffbf12' }}>registering a company</span>
      <br />
      And <span style={{ color: '#ffbf12' }}>trademarking </span>
      <br />
      your profile picture
      <br />
      & username <br />
      you *could turn <br />
      <span style={{ color: '#ffbf12' }}>
        your personal brand <br />
        into a tangible asset.
      </span>
      <br />
      <br />
      *You could register a company <br />
      and trademark your
      <br /> social media profile <br />
      <span style={{ color: '#ffbf12' }}>before or after</span> adding value to
      <br />
      your personal brand <br />
      with PassionStocks, <br />
      <span style={{ color: '#ffbf12' }}>
        allowing you to{width === 'xs' && <br />} test the waters.
      </span>
    </Typography>,

    <Typography
      className={classes.textMonarcha}
      sx={{
        position: 'absolute',
        top: { xs: '-137%', sm: '15%' },
        left: { xs: '-35%', sm: '50%' },
        transform: { xs: '', sm: 'translate(-50%,-50%)' },
        fontSize: { xs: '12px ', sm: scale * 0.1 },
        color: '#909090',
        width: 'max-content',
        textAlign: 'center',
        lineHeight: 1.2,
      }}
    >
      You can achieve a $1B valuation <br />
      <span style={{ color: '#ffbf12' }}>on your own</span> or
      {width === 'xs' && <br />}
      <span style={{ color: '#ffbf12' }}> with other creators.</span>
    </Typography>,
  ];
  return (
    <Box
      sx={{
        height: { xs: scale * 4.5, sm: index === 2 ? scale * 4 : scale * 3.25 },
        marginBottom: scale * 0.08,
      }}
    >
      <Box>
        <Typography
          className={classes.textMonarcha}
          variant="h5"
          sx={{
            fontSize: width === 'xl' ? '2.1rem !important' : '2rem !important',
            textAlign: 'center',
            lineHeight: 1.3,
            marginBottom: { xs: 1, sm: 0 },
            color: '#ffbf12',
          }}
        >
          Turn your social media into{width === 'xs' && <br />} $1B startup company
        </Typography>
        <Typography
          className={classes.textOpenSans}
          variant="subtitle1"
          sx={{
            textAlign: 'center',
            lineHeight: 1.3,
            // fontSize: { xs: '14px', sm: '20px' },
            color: '#ffffff77',
          }}
        >
          It’s not easy, but with our ecosystem, {width === 'xs' && <br />}it’s possible.
        </Typography>
        <img
          src={SocialTo1B}
          style={{ display: 'block', margin: '20px auto', width: `${scale * 1.7}px` }}
        />
      </Box>

      <StepsContent
        index={index}
        setIndex={setIndex}
        stepDescription={stepDescription}
        stepsNames={stepsNames}
        scale={scale}
        width={width}
      />
    </Box>
  );
}
export default withWidth()(PersonalBrandProcess);
