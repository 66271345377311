import './style.css';

import { useSelector } from 'react-redux';

import BackBtn from '../../images/back-page.png';
import { useNavigate } from 'react-router-dom';
import store from '../../store/store';
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectNavigateBackLocation = (state) => state.navigateBackLocation;

export default function CookiePolicy() {
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigateBackLocation = useSelector(selectNavigateBackLocation);

  const navigate = useNavigate();

  return (
    <div className="CookiePolicy Page headline" style={{ height: windowInnerSize.height }}>
      <img
        src={BackBtn}
        alt="Back to previous page"
        className="CookiePolicy__back-btn"
        onClick={() => {
          navigate(navigateBackLocation.page);
        }}
      />
      <div
        style={{
          padding: ' 2vw 5vw 5vw',
          maxWidth: '1400px',
          margin: '0 auto',
        }}
      >
        <h1> Cookie Policy for PassionStocks.com</h1>
        <p>
          <span style={{ fontSize: 12 + 'pt' }}>Effective Date: 10.30.2023</span>
        </p>
        <p>
          PassionStocks.com (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) uses cookies and
          similar technologies on our website. This Cookie Policy explains what cookies are, how we
          use them, and your choices regarding their use.
        </p>
        <h2> 1. What are Cookies?</h2>

        <p>
          Cookies are small text files that are stored on your computer or device when you visit a
          website. They help websites recognize your device and remember your preferences and
          actions over time. Cookies can serve various purposes, such as enabling essential website
          functions, improving user experience, and providing targeted advertising.
        </p>
        <h2> 2. Our Use of Cookies</h2>
        <p>
          We use cookies to enhance your experience on PassionStocks.com. The cookies we use can be
          categorized as follows:
        </p>
        <p>
          <span> Essential Cookies:</span> &nbsp;These cookies are necessary for the basic
          functionality of our website. They enable you to navigate our site and use its features.
          You cannot disable essential cookies.
        </p>
        <p>
          <span>Targeted Advertising Cookies:</span> &nbsp;These cookies are used to deliver
          advertising that is more relevant to you and your interests. They may also help limit the
          number of times you see an advertisement and measure the effectiveness of advertising
          campaigns. Advertising networks typically place them with our permission.
        </p>
        <p>
          <span>Personalization Cookies:</span> &nbsp;These cookies allow our website to remember
          choices you make, such as your username, language, or region, providing enhanced, more
          personalized features. For example, we may use these cookies to provide you with local
          weather reports or traffic news by storing data about your general location.
        </p>
        <p>
          <span> Analytics Cookies:</span> &nbsp;These cookies help us understand how our website
          performs, how visitors interact with it, and whether there may be technical issues. They
          help us improve our website&apos;s functionality and user experience.
        </p>
        <h2> 3. Your Cookie Preferences</h2>
        <p>
          You have the option to manage your cookie preferences. To do so, click on the
          &quot;cookie&quot; icon located at the bottom right of our website. You can then change
          your cookie preferences by toggling the switches next to the respective categories:
        </p>
        <ul>
          <li>
            <p>
              <span>Essential&nbsp;</span>
              (You may not disable essential cookies.)
            </p>{' '}
          </li>
          <li>
            <p>
              <span>Targeted Advertising</span>
            </p>
          </li>
          <li>
            <p>
              <span>Personalization</span>
            </p>
          </li>
          <li>
            <p>
              <span>Analytics</span>
            </p>
          </li>
          <li>
            <p>
              <span>Do Not Sell or Share My Personal Information</span>
            </p>
          </li>
        </ul>
        <p>
          By switching the toggle to the right and clicking &quot;Save,&quot; you indicate whether
          you want us to sell or share your personal information for online targeted advertising
          activities.
        </p>
        <p>
          Please note that if you use different computers or browsers, you must indicate your
          choices again on each computer or browser used to access our services.
        </p>
        <h2> 4. Additional Information&nbsp;</h2>
        <p>
          For more information about how we handle your personal data and your rights under data
          protection laws, please refer to our{" "}
          <span
            onClick={() => {
              store.dispatch({
                type: 'ui/navigateBackLocation/add',
                payload: { page: '/cookie-policy',from: navigateBackLocation.from, typePage: navigateBackLocation.typePage },
              });
              navigate('/privacy-policy');
            }}
            style={{cursor:'pointer',fontWeight:'lighter', textDecoration: 'underline' }}
          >
            Privacy Policy
          </span>{' '}
          .
        </p>
        <p>
          Our use of cookies is powered by Osano. For more information about Osano&apos;s services,
          you can visit their website at
          <a href="https://chat.openai.com/www.osano.com">&nbsp;www.osano.com</a>.{' '}
        </p>
        <h2>5. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Cookie Policy or our use of cookies,
          please contact us at info@passionstocks.com.
        </p>
        <p>
          This Cookie Policy may be updated from time to time. Please check this page periodically
          to ensure you are aware of any changes.
        </p>
      </div>
    </div>
  );
}
