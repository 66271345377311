import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy2() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);

  useEffect(() => {
    const Copy2AElement = document.getElementById('Copy2A');
    const calculatedMarginA = (window.innerHeight - Copy2AElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container>
      <div id="Copy2A" style={{ margin: `${marginsA}px auto 60px` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.6 }}
        >
          Wondering{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          How does <br />
          our One-product <br />
          marketplace
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.6, marginBottom: 3 }}
        >
          For shoutouts{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ lineHeight: 1.1, marginBottom: 0 }}
        >
          Add tangible value To <br />
          your personal brand?
        </Typography>
      </div>
    </Container>
  );
}
