import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import JoinTheWaitlistButton from '../../../../components/buttons/JoinTheWaitlist';
import HorizontalLine from '../../../../components/elements/HorizontalLine';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy6A() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);
  const [marginsC, setmarginsC] = useState(0);
  const [marginsD, setmarginsD] = useState(0);
  const [marginsE, setmarginsE] = useState(0);
  const [marginsF, setmarginsF] = useState(0);

  useEffect(() => {
    const Copy6AAElement = document.getElementById('Copy6AA');
    const calculatedMarginA = (window.innerHeight - Copy6AAElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const Copy6ABElement = document.getElementById('Copy6AB');
    const calculatedMarginB = (window.innerHeight - Copy6ABElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
    const Copy6ACElement = document.getElementById('Copy6AC');
    const calculatedMarginC = (window.innerHeight - Copy6ACElement.clientHeight) / 2;
    setmarginsC(
      calculatedMarginC > window.innerHeight / 4 ? calculatedMarginC : window.innerHeight / 4,
    );
    const Copy6ADElement = document.getElementById('Copy6AD');
    const calculatedMarginD = (window.innerHeight - Copy6ADElement.clientHeight) / 2;
    setmarginsD(
      calculatedMarginD > window.innerHeight / 4 ? calculatedMarginD : window.innerHeight / 4,
    );
    const Copy6AEElement = document.getElementById('Copy6AE');
    const calculatedMarginE = (window.innerHeight - Copy6AEElement.clientHeight) / 2;
    setmarginsE(
      calculatedMarginE > window.innerHeight / 4 ? calculatedMarginE : window.innerHeight / 4,
    );
    const Copy6AFElement = document.getElementById('Copy6AF');
    const calculatedMarginF = (window.innerHeight - Copy6AFElement.clientHeight) / 2;
    setmarginsF(
      calculatedMarginF > window.innerHeight / 4 ? calculatedMarginF : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{}}>
      <div id="Copy6AA" style={{ margin: `${marginsA}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          They get{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          exclusive story
          <br />
          shoutout
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          you can also offer them{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Business Packages{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 3 }}
        >
          with your shoutout sales, based <br />
          on the quantity purchased.
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          you can also offer them{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Real-time marketing
          <br />
          In a preset format{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 3 }}
        >
          <span style={{ color: '#ffbf12' }}>just pay & promote.</span>
          <br />
          Turning <br />
          <span style={{ color: '#ffbf12' }}>moments into momentum </span>
          <br />
          for your brand and theirs.
        </Typography>
      </div>

      <div id="Copy6AB" style={{ margin: `${marginsB}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          PassionStocks?
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          PassionStocks takes{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          up to 20%
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          of Story shoutouts
          <br />
          sales revenue.{' '}
        </Typography>
      </div>

      <div id="Copy6AC" style={{ margin: `${marginsC}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Still not sure{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          how to come up with <br />
          a strategy <br />
          for scalable sales?{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Join the{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 10 }}
          variant="h2"
        >
          Passionstocks <br />
          Academy{' '}
        </Typography>
        <JoinTheWaitlistButton />{' '}
      </div>

      <div id="Copy6AD" style={{ margin: `${marginsD}px auto` }}>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          don’t have time <br />
          to manage your <br />
          personal brand as
          <br />A startup?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          get a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 2 }}
          variant="h2"
        >
          Personal brand
          <br />
          Manager{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          <span style={{ color: '#ffbf12' }}> Invite your own manager</span> or <br />
          <span style={{ color: '#ffbf12' }}> find a manager on PassionStocks</span>.
        </Typography>
      </div>

      <div id="Copy6AE" style={{ margin: `${marginsE}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          What’s in It for a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          Personal brand
          <br />
          Manager?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          They can set a{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 1 }}
          variant="h2"
        >
          Management fee{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.2, marginBottom: 1 }}
        >
          from the shoutout sales revenue.
        </Typography>
      </div>

      <div id="Copy6AF" style={{ margin: `${marginsF}px auto` }}>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', lineHeight: 1.5, marginBottom: 1 }}
        >
          Still Wondering how{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 3 }}
          variant="h2"
        >
          PassionStocks helps
          <br />
          To secure
          <br />
          your financial future?{' '}
        </Typography>

        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffbf12', lineHeight: 1.2, marginBottom: 3 }}
        >
          PassionStocks helps to turn <br />
          your personal brand into <br />
          a tangible asset by focusing on <br />
          one scalable income stream:
          <br />
          selling shoutouts.{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ lineHeight: 1.1, marginBottom: 10 }}
          variant="h2"
        >
          This asset grows <br />
          with your activities <br />
          and can be insured <br />
          for long-term <br />
          Financial security.{' '}
        </Typography>
        <JoinTheWaitlistButton />
        <br />
        <br />
        <br />
        <Container sx={{ width: '250px' }}>
          <HorizontalLine />
        </Container>
      </div>
    </Container>
  );
}
