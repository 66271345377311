import { Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import TYMentionEx1 from '../../../../../../../images/ty-mention-ex1.png';
import TYMentionEx2 from '../../../../../../../images/ty-mention-ex2.png';
import TYMentionEx3 from '../../../../../../../images/ty-mention-ex3.png';
import ImageGallery from '../../../../../components/ImageGallery';

export default function OneProduct__TextAndTagging(props) {
  return (
    <FadeIn>
      <Container>
        <Typography
          className={props.fontStyle}
          variant="subtitle1"
          sx={{
            color: '#ffffff44',
            lineHeight: 1.3,
            textAlign: 'center',
            marginTop: 2,
            marginBottom: 4,
          }}
        >
          The <span style={{ color: '#ffbf12' }}>buyer</span> gets a{' '}
          <span style={{ color: '#ffbf12' }}>shoutout</span> with{' '}
          <span style={{ color: '#ffbf12' }}>just</span> their{' '}
          <span style={{ color: '#ffbf12' }}>Instagram handle</span>.<br />
          It’s a <span style={{ color: '#ffbf12' }}>fixed templatE — text & tagging only</span> with
          no option to add text or images, <br />
          and the <span style={{ color: '#ffbf12' }}>delivery is fully automated</span>
          <br />
          <br />{' '}
          <span style={{ color: '#ffbf12' }}>
            Below, you can see examples of delivered Shoutouts.
          </span>
        </Typography>
        <ImageGallery images={[TYMentionEx1, TYMentionEx2, TYMentionEx3]} thumbnail />
      </Container>
    </FadeIn>
  );
}
