import { withWidth } from '@material-ui/core';
import { Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const selectWindowInnerSize = (state) => state.windowInnerSize;

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

const FindOutMoreButton = ({ width, ...props }) => {
  let { variant, onClick, pageType } = props;
  if (width !== 'xl') {
    variant = 'small';
  }
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Container>
      <Button
        className={classes.textOpenSans}
        sx={{
          textTransform: 'none',
          color: 'white',
          fontWeight: 'bold',
          fontSize: variant === 'small' ? '14px' : '20px',
          lineHeight: variant === 'small' ? '14px' : '20px',
          padding: variant === 'small' ? '9px 90px' : '14px 95px',
          background: '',
          border: '1px solid #ffbf12',
          borderRadius: '50px',
          margin: '0px auto 0',
          display: 'block',
          '&:hover': { background: '#ffbf1222' },
        }}
        // variant="outlined"
        onClick={handleClick}
      >
        Find Out More{' '}
      </Button>
    </Container>
  );
};

export default withWidth()(FindOutMoreButton);
