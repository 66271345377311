import React from 'react';
import { Alert, Container, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { useEffect, useState } from 'react';

import LongLogo from '../../../../images/ps-logo-one-row.png';
import YoutubeLogoBW from '../../../../images/YoutubeLogoBW.png';
import InstagramLogoBW from '../../../../images/InstagramLogoBW.png';
import FacebookLogoBW from '../../../../images/FacebookLogoBW.png';
import PSLogo from '../../../../images/PassionStocksIcon.png';
import PSLBckg from '../../../../images/PSLBckg.png';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import isMobileDevice from '../../../../utiles/isMobileDevice';
import store from '../../../../store/store';

const selectWindowInnerSize = (state) => state.windowInnerSize;

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Footer({ typePage }) {
  const classes = useStyles();
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const [isPhone, setisPhone] = useState(null);
  useEffect(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const PhoneScreen = vw < 600;
    // console.log(PhoneScreen + ',' + isMobileDevice());

    setisPhone(PhoneScreen || isMobileDevice());
  }, [windowInnerSize]);

  return (
    <div
      id="BottomDivHD"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage: `url(` + PSLBckg + `)`,
        height: windowInnerSize.height,
        position: 'relative',
        top: '0',
        left: '0',
        margin: 0,
        zIndex: 0,
        transition: 'opacity 0.2s', // Add a transition effect
      }}
    >
      <div />
      <div>
        <img
          src={LongLogo}
          alt="LongLogo"
          className="LandingPage__LongLogo"
          style={{ width: isPhone ? '18em' : '20em' }}
        />
        <br />
        <p
          className="LandingPage__FooterTxt headline"
          style={{ fontSize: isPhone ? '0.8em' : '1.2em' }}
        >
          PassionStocks is a socials to stocks platform for creators.
          <br />
          <br />
          <p style={{ fontWeight: 'normal', margin: 0 }}>
            Helping creators turn their personal brands into stocks by selling shoutouts
          </p>
          <br />
          (And no. we are not a crypto platform)
          <br />
          <span style={{ color: '#b8b8b8' }}>______</span>
        </p>

        <p
          id="EndDescriptionHD"
          className="headline"
          style={{
            margin: '4vh auto',
            fontSize: isPhone ? '0.8em' : '1.1em',
            lineHeight: '1.7em',
            textAlign: 'center',
          }}
        >
          Empowering creators and their communities to team up and turn
          <br />
          a creator’s personal brand into a valuable startup,
          <br />
          aiming for a stock exchange listing.{' '}
        </p>
      </div>
      <div
        className="LandingPage__Footer__LinksContainer"
        style={{ flexDirection: isPhone ? 'column' : 'row' }}
      >
        <div
          className="LandingPage__Footer__Links headline"
          style={{ fontSize: isPhone ? '0.75em' : '1.2em' }}
        >
          <p
            onClick={() => {
              store.dispatch({
                type: 'ui/navigateBackLocation/add',
                payload: { page: '/', from: 'footer', typePage: typePage },
              });
              navigate('/terms-and-conditions');
            }}
            style={{ cursor: 'pointer' }}
          >
            T's {' & '}
            C's{' '}
          </p>
          <p
            onClick={() => {
              store.dispatch({
                type: 'ui/navigateBackLocation/add',
                payload: { page: '/', from: 'footer', typePage: typePage },
              });
              navigate('/cookie-policy');
            }}
            style={{ cursor: 'pointer' }}
          >
            Cookie Policy
          </p>
          <p
            onClick={() => {
              store.dispatch({
                type: 'ui/navigateBackLocation/add',
                payload: { page: '/', from: 'footer', typePage: typePage },
              });
              navigate('/privacy-policy');
            }}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            Privacy Policy{' '}
          </p>
          <Link to="https://passionstocks.helpsite.com/" rel="noopener noreferrer">
            {/* target="_blank" */}
            <p
              onClick={() => {
                store.dispatch({
                  type: 'ui/navigateBackLocation/add',
                  payload: { page: '/', from: 'footer', typePage: typePage },
                });
              }}
            >
              {' '}
              FAQs{' '}
            </p>
          </Link>
        </div>

        <div id="FindUsEndHD">
          <img
            src={PSLogo}
            alt=""
            className="LandingPage__Footer__SMLink"
            id="WhatsappLogoBWHD"
            onClick={() => {
              const scrollPositionUp = document.getElementById(
                typePage === 'agency' ? 'AgencyPage' : 'LandingPage1',
              );
              scrollPositionUp.scrollTo({
                top: scrollPositionUp.scrollHeight - windowInnerSize.height * 1.95,
              });
            }}
          />
          <a href="https://www.facebook.com/PassionStocks">
            <img
              src={FacebookLogoBW}
              alt=""
              className="LandingPage__Footer__SMLink"
              id="FacebookLogoBWHD"
            />
          </a>
          <a href="https://www.instagram.com/passionstocks/">
            <img
              src={InstagramLogoBW}
              alt=""
              className="LandingPage__Footer__SMLink"
              id="InstagramLogoBWHD"
            />
          </a>
          <a href="https://www.youtube.com/channel/UCewSZEyV9qce9dnuUTY5Qqw">
            <img
              src={YoutubeLogoBW}
              alt=""
              className="LandingPage__Footer__SMLink"
              id="YoutubeLogoBWHD"
            />
          </a>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
