import './style.css';

import { useSelector } from 'react-redux';

import BackBtn from '../../images/back-page.png';
import { useNavigate } from 'react-router-dom';
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectNavigateBackLocation = (state) => state.navigateBackLocation;

export default function TsAndCs() {
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigateBackLocation = useSelector(selectNavigateBackLocation);

  const navigate = useNavigate();

  return (
    <div className="TsAndCs Page headline" style={{ height: windowInnerSize.height }}>
      <img
        src={BackBtn}
        alt="Back to previous page"
        className="TsAndCs__back-btn"
        onClick={() => {
          navigate(navigateBackLocation.page);
        }}
      />
      <div
        style={{
          padding: ' 2vw 5vw 5vw',
          maxWidth: '1400px',
          margin: '0 auto',
        }}
      >
        <h1>Terms and Conditions</h1>
        <br />
        <h2>1. Acceptance of Terms</h2>
        <p>
          By joining the waitlist on our website (&quot;Website&quot;), you agree to be bound by
          these terms and conditions (&quot;Terms&quot;). If you do not agree to these Terms, please
          do not use the waitlist feature.
        </p>
        <br />
        <h2>2. Waitlist Description</h2>
        <p>
          Our Waitlist allows you to express your interest in joining our platform, a personal brand
          management service that aims to prepare individuals for potential listing on the stock
          exchange through an initial public offering (IPO). Please note that registration to the
          platform is subject to separate Terms and Conditions.
        </p>
        <br />
        <h2>3. Email Communication</h2>
        <p>
          By joining the waitlist, you grant us permission to send you occasional emails with
          updates, news, and information about the platform. You can adjust your email preferences
          or unsubscribe at any time by sending us an email to info@passionstocks.com.
        </p>
        <br />
        <h2>4. Future Registration</h2>
        <p>
          Please understand that joining the waitlist does not constitute registration on the
          platform. Registering to the platform is subject to a separate set of Terms and Conditions
          that will be provided to you when the registration process becomes available.
        </p>
        <br />
        <h2>5. Privacy</h2>
        <p>
          Your privacy is important to us. Any information you provide when joining the waitlist
          will be treated in accordance with our Privacy Policy. For more details on how we handle
          your data, please refer to the Privacy Policy.
        </p>
        <br />
        <h2>6. Contact Rights</h2>
        <p>
          By participating in the waitlist, you acknowledge that we retain the discretion to
          determine whether or not to contact you based on the information you&apos;ve provided.
        </p>
        <br />
        <h2>7. No Offer</h2>
        <p>
          You acknowledge that the descriptions and information provided on the Website regarding
          our services are for informational purposes only and do not constitute a contractual offer
          or commitment. PassionStocks also reserves the right to change, modify, or enhance its
          products, services, and offerings without any obligation to users who have expressed
          interest in the Waitlist.
        </p>
        <br />
        <h2>8. FAQs and Social Media</h2>
        <p>
          For your convenience, we maintain a Frequently Asked Questions (FAQs) section on our
          Website. This section is designed to provide insights into the overarching goals and
          aspirations of our product, which focuses on preparing individuals for potential listing
          on the stock exchange through an initial public offering (IPO). It offers information
          related to our registration process and other relevant aspects. It&apos;s important to
          note that the FAQs&apos; content may change to align with our product&apos;s dynamic
          nature. The FAQs aim to provide a broad understanding of our product&apos;s mission and
          direction, rather than constituting a contractual offer or commitment. Furthermore,
          updates or alterations to specific features, services, or offerings may not be immediately
          reflected in the FAQs, and their availability may vary depending on jurisdictional and
          legal considerations.
        </p>
        <br />
        <p>
          Similarly, our social media platforms may share information about our product&apos;s
          overarching goals and aspirations, along with updates on our journey. Please be aware that
          the content shared on our social media channels is subject to frequent updates and
          adjustments to reflect the evolving nature of our product. These posts are meant to inform
          and inspire, rather than serve as binding offers or commitments. It&apos;s important to
          note that information shared on social media may not always reflect the current
          availability of specific features, as our focus is primarily on the preparation aspect of
          personal brand management for potential listing on the stock exchange, rather than direct
          involvement in the stock exchange process.
        </p>
        <br />
        <h2>9. Modifications</h2>
        <p>
          We reserve the right to modify these Waitlist Terms and Conditions at any time. Any
          changes will be effective upon posting on the Website. Your continued use of the Waitlist
          after such modifications constitutes your acceptance of the revised Terms.
        </p>
        <br />
        <h2>10. Governing Law</h2>
        <p>
          These Waitlist Terms and Conditions are governed by and construed in accordance with the
          laws of the Republic of South Africa. Any disputes arising out of or in connection with
          these Terms shall be subject to the exclusive jurisdiction of the courts of the Republic
          of South Africa, in accordance with South African law.
        </p>
        <br />
        <p>&ndash;</p>
        <p>
          This website is operated by Virtual Crypto SA (Pty) Ltd, registered in the Republic of
          South Africa.
        </p>
      </div>
    </div>
  );
}
