import './style.css';
import store from '../../../store/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WinkingEmoji from '../../../images/emoji-winking.png';
import BackBtn from '../../../images/back-page.png';
import { Box, Typography } from '@mui/material';
import { withWidth } from '@material-ui/core';
const selectWindowInnerSize = (state) => state.windowInnerSize;

const GeneralError = ({ width, ...props }) => {
  store.dispatch({
    type: 'ui/navBarsDM/toggle',
    payload: { top: true, bottom: true },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();
  return (
    <div
      className="GeneralError Page headline"
      style={{ height: windowInnerSize.height, width: '100vw', display: 'flex' }}
    >
      <img
        src={BackBtn}
        alt="back button"
        style={{
          position: 'absolute',
          left: 0,
          top: width === 'xs' ? '5vh' : '8vh',
          width: width === 'xs' ? '50px' : '70px',
          cursor:'pointer'
        }}
        onClick={() => {
          navigate(-1);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <img
          src={WinkingEmoji}
          alt="Winking emoji"
          style={{ width: width === 'xs' ? '80px' : '120px' }}
        />
        <Typography
          variant={width === 'xs' ? 'h4' : 'h3'}
          sx={{ marginTop: width === 'xs' ? 3 : 6, lineHeight: 1.1, textAlign: 'center' }}
        >
          Oops, an error occurred.
          <br />
          Please try again later.
        </Typography>
      </Box>
    </div>
  );
};
export default withWidth()(GeneralError);
