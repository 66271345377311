import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Dialog, IconButton, Typography } from '@mui/material';
import PSStations from './slides/PSStations';
import { withWidth } from '@material-ui/core';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  root: {
    position: 'relative',
    width: '100%',
    // height: '100vh',
    overflow: 'hidden',
    padding: 0,
  },
  slide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: '#fff',
    fontSize: '30px',
  },
  slide1: {
    backgroundColor: '',
  },
  slide2: {
    backgroundColor: '',
  },
  slide3: {
    backgroundColor: '',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  navButtonLeft: {
    left: 0,
  },
  navButtonRight: {
    right: 0,
  },
});

const Agency__PSStations = ({ width, ...props }) => {
  const classes = useStyles();

  // use for slides height elements:
  const [height, setheight] = useState(0);

  const [index, setIndex] = useState(0);

  // const handleChangeIndex = (index) => {
  //   setIndex(index);
  // };

  return (
    <Box className={classes.root} sx={{ padding: 0 }}>
    
      <Typography
        className={classes.textMonarcha}
        variant="h5"
        sx={{
          fontSize: width === 'xl' ? '1.85rem !important' : '1.8rem !important',

          textAlign: 'center',
          lineHeight: 1.3,
          marginBottom: { xs: 1, sm: 0 },
          color: '#ffbf12',
        }}
      >
       Creator agency startup Journey on PassionStocks   </Typography>
      <Typography
        className={classes.textOpenSans}
        variant="subtitle1"
        sx={{
          textAlign: 'center',
          lineHeight: 1.3,
          // fontSize: { xs: '14px', sm: '20px' },
          color: '#ffffff77',
          marginBottom:3
        }}
      >
       PassionStocks lets creator agencies launch their business as a startup company.</Typography>
      <PSStations
        isPhone={props.isPhone}
        className={`${classes.slide}`}
        setIndex={setIndex}
        setheight={setheight}
      />{' '}
      {/* </div>
        <div>
          <PSAccountManager className={`${classes.slide}`} setIndex={setIndex} height={height}/>
        </div>
      </SwipeableViews> */}
        </Box>
  );
};

export default withWidth()(Agency__PSStations);
