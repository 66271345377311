import { Box, Container, Typography } from '@mui/material';
import FadeIn from '../../../../../../../layouts/fade-in';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import NumberedCirclesButtons from '../../../../../../../components/steps/NumberedCirclesButtons';
import { makeStyles } from '@mui/styles';
import JoinTheWaitlistButton from '../../../../../../../components/buttons/JoinTheWaitlist';

const useStyles = makeStyles({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
});

export default function OneProduct__StoryShoutoutOI(props) {
  const classes = useStyles();

  return (
    <Box sx={{ width: '58vw', height: '100%', paddingTop: 25 }}>
      <div>
        <Typography
          className={props.fontStyle}
          variant="h5"
          sx={{
            position: 'relative',
            display: 'block',
            textAlign: 'center',
            color: '#ffffff',
            lineHeight: 1.2,
            fontSize: '13px !important',
            marginTop: 2,
          }}
        >
          On PassionStocks,
          <br /> <span style={{ color: '#ffbf12' }}>creators</span> can{' '}
          <span style={{ color: '#ffbf12' }}>
            sell <br /> only one product:
            <br />
            <br />
            shoutout <br />
          </span>
          on instagram story.{' '}
        </Typography>{' '}
      </div>
    </Box>
  );
}
