import { Typography } from '@mui/material';
import ImageGallery from '../../../../../components/ImageGallery';
import { Box } from '@material-ui/core';

export default function ScalabilityDrives__Versatility(props) {
  return (
    <Box sx={{ width: '6vw', height: '100%', marginTop: 0 }}>
      <Typography
        className={props.fontStyle}
        variant="subtitle1"
        sx={{
          color: '#ffffff44',
          lineHeight: 1.1,
          textAlign: 'center',
          margin: 0,
          marginTop:6,
          marginLeft: 1,
          marginBottom: 4,
          fontSize: '11px !important',
        }}
      >
        <span style={{ color: '#ffbf12' }}> Shoutout campaigns</span> let you{' '}
        <span style={{ color: '#ffbf12' }}>
          sell Shoutouts for different purposes, <br />
          making shoutout sales more scalable.
        </span>
        <br />
        —whether to fund your next venture, open a coffee shop, launch a fashion line,
        <br /> or support a non-profit.
        <br />
        <br /> They{' '}
        <span style={{ color: '#ffbf12' }}>
          highlight your sales skills and the strength of your personal brand
        </span>
        , <br />
        with{' '}
        <span style={{ color: '#ffbf12' }}>each successful campaign adding tangible value </span>
        <br />
        and showcasing your brand’s influence.
      </Typography>

      <ImageGallery
        thumbnail
        flexDirection={'column'}
        sizes={['90%']}
        images={['/images/scalability-drives-versatility-mobile.png']}
      />
      {/* </div> */}
    </Box>
  );
}
