import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  textMonarcha: {
    fontFamily: 'monarcha,open-sans !important',
    textTransform: 'uppercase !important',
    color: 'white ',
    fontWeight: 'bold !important',
    textAlign: 'center',
  },
  textOpenSans: {
    fontFamily: 'open-sans,san-serif !important',
    textTransform: 'normal',
    color: 'white',
  },
  glow: {
    textShadow: '0 0 0.1vw #fff, 0 0 0.05vw #fff, 0 0 1.1vw #ffbf12',
  },
}));

export default function Copy2A() {
  const classes = useStyles();

  const [marginsA, setmarginsA] = useState(0);
  const [marginsB, setmarginsB] = useState(0);

  useEffect(() => {
    const Copy2AAElement = document.getElementById('Copy2AA');
    const calculatedMarginA = (window.innerHeight - Copy2AAElement.clientHeight) / 2;
    setmarginsA(
      calculatedMarginA > window.innerHeight / 4 ? calculatedMarginA : window.innerHeight / 4,
    );
    const Copy2ABElement = document.getElementById('Copy2AB');
    const calculatedMarginB = (window.innerHeight - Copy2ABElement.clientHeight) / 2;
    setmarginsB(
      calculatedMarginB > window.innerHeight / 4 ? calculatedMarginB : window.innerHeight / 4,
    );
  }, []);

  return (
    <Container sx={{marginTop:2}}>
      <div id="Copy2AA" style={{ margin: `0 auto ${marginsA}px` }}>
        {' '}
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          Since
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          sx={{ marginBottom: 1 }}
          variant="h2"
        >
          Each Shoutout’s Value
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          On our marketplace comes
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 1 }}
        >
          Entirely
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 1 }}
        >
          From the
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 1 }}
        >
          Personal brand
        </Typography>
        <Typography
          className={classes.textMonarcha}
          variant="h4"
          sx={{ color: '#ffffff44', marginBottom: 10 }}
        >
          - every sale adds <br />
          tangible value to that brand.{' '}
        </Typography>
      </div>

      <div id="Copy2AB" style={{ margin: `${marginsB}px auto 0` }}>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          our marketplace is where
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          Every creator
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          Sells the
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1 }}
        >
          Same <br />
          exact product
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          Under the{' '}
        </Typography>
        <Typography
          className={classes.textMonarcha + ' ' + classes.glow}
          variant="h2"
          sx={{ textAlign: 'center', lineHeight: 1.1, marginBottom: 7 }}
        >
          Same
          <br /> market conditions{' '}
        </Typography>

        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          the
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          different <br />
          sales volumes{' '}
        </Typography>
        <Typography className={classes.textMonarcha} variant="h4" sx={{ color: '#ffffff44' }}>
          show each
        </Typography>
        <Typography className={classes.textMonarcha + ' ' + classes.glow} variant="h2">
          personal brand’s <br />
          unique value.
        </Typography>
      </div>
    </Container>
  );
}
